import { appConstants } from "redux/constants";
import { userConstants } from "redux/constants";
import Axios from "utils/axios";

export const login = (email, password) => {
  return async dispatch => {
    try{
      dispatch({ type: userConstants.USER_LOGIN_REQUEST });
      const config = { 
        headers: {
          "Content-Type": "application/json"
        }, 
        withCredentials: true, 
        credentials: "include"
      };
      const { data } = await Axios.post(`/auth/login`, { email, password }, config);            
      dispatch({ type: userConstants.USER_LOGIN_SUCCESS, payload: data.data });      
      dispatch(loadUser());
    }catch(error){
      dispatch({ 
        type: userConstants.USER_LOGIN_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}

export const loadUser = () => {
  return async dispatch => {
    try{
      dispatch({ type: userConstants.LOAD_USER_REQUEST });
      const { data } = await Axios.get(`/auth/profile`);           
      dispatch({ type: userConstants.LOAD_USER_SUCCESS, payload: data.data });
    }catch(error){
      dispatch({ 
        type: userConstants.LOAD_USER_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}

export const forgotPassword = (email) => {
  return async dispatch => {
    try{
      dispatch({ type: userConstants.FORGOT_PASSWORD_REQUEST });
      const config = { 
        headers: {
          "Content-Type": "application/json"
        }        
      };
      const { data } = await Axios.post(`/user/forgot-password`, { email }, config);      
      dispatch({ 
        type: userConstants.FORGOT_PASSWORD_SUCCESS, 
        payload: {success: data.success, message: data.data.message} 
      });
    }catch(error){
      dispatch({ 
        type: userConstants.FORGOT_PASSWORD_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}

export const logout = () => {
  return async dispatch => {
    try{      
      await Axios.get(`/auth/logout`);      
      dispatch({ type: userConstants.LOGOUT_SUCCESS });
      //RESET ALL REDUCERS   
      dispatch({ type: appConstants.RESET });                     
    }catch(error){
      dispatch({ 
        type: userConstants.LOGOUT_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}

export const updateProfile = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: userConstants.UPDATE_PROFILE_REQUEST });     
      const { data } = await Axios.put(`/auth/update-profile`, form);      
      dispatch({ 
        type: userConstants.UPDATE_PROFILE_SUCCESS, 
        payload: { success: data.success } 
      });
    }catch(error){
      dispatch({ 
        type: userConstants.UPDATE_PROFILE_FAILURE, 
        payload: error.response.data.error 
      });
    }
  }
}