import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Loader } from "atoms";
import { appConstants } from "redux/constants";
import Dashboard from "pages/dashboard";
import Tools from "pages/tools";
import NotFound from "pages/not-found";
import Event from "pages/event";
import ExamSchedule from "pages/exam-schedule";
import Result from "pages/result";
import Gallery from "pages/gallery";
import News from "pages/news";
import ImpLink from "pages/imp-link";
import NEPSyllabus from "pages/nep-syllabus";
import EntranceSyllabus from "pages/entrance-syllabus";
import Recruitment from "pages/recruitment";
import Sports from "pages/sports";
import MediaCoverage from "pages/media-coverage";
import ExamForm from "pages/exam-form";
import EntranceExam from "pages/entrance-exam";
import CBDUsers from "pages/cbd-users";
import Campus from "pages/campus";
import SGRUsers from "pages/sgr-users";

export const AppRoutes = () => {
  
  const dispatch = useDispatch();
  const { initLoading, error, message, loading } = useSelector(state => state.app);  

  useEffect(()=>{
    if(error){
      toast.error(error)
      dispatch({type: appConstants.RESET_APP})
    }
    if(message){
      toast.success(message)
      dispatch({type: appConstants.RESET_APP})
    }
  }, [error, message, dispatch]);
  
  if(initLoading) return <Loader />

  return (
    <Suspense fallback={<Loader />}>      
      {loading && <Loader />}
      <Routes>
        <Route path="/" exact element={<Navigate to="/dashboard" replace />} />        
        <Route path="/dashboard" exact element={<Dashboard />} />  
        <Route path="/tools" exact element={<Tools />} />        
        <Route path="/events" exact element={<Event />} />
        <Route path="/exam-schedule" exact element={<ExamSchedule />} />        
        <Route path="/result" exact element={<Result />} />        
        <Route path="/gallery" exact element={<Gallery />} />
        <Route path="/news" exact element={<News />} />        
        <Route path="/important-links" element={<ImpLink />} />
        <Route path="/nep-syllabus" exact element={<NEPSyllabus />} />
        <Route path="/entrance-syllabus" exact element={<EntranceSyllabus />} />        
        <Route path="/recruitment" exact element={<Recruitment />} />        
        <Route path="/sports" exact element={<Sports />} />        
        <Route path="/media-coverage" exact element={<MediaCoverage />} />        
        <Route path="/exam-form" exact element={<ExamForm />} />        
        <Route path="/entrance-exam" exact element={<EntranceExam />} />
        <Route path="/cbd-users" exact element={<CBDUsers />} />        
        <Route path="/campus" exact element={<Campus />} />             
        <Route path="/sgr-users" exact element={<SGRUsers />} />           
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};