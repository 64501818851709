import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { Flex, Text } from "atoms";

export const Fold = ({
  title,
  fold,
  setFold
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      mb={!fold && "1.5rem"}
    >
      <Text
        fontSize="1.6rem"
        fontWeight="bold"
        color="dark.500"
        textTransform="capitalize"
      >
        {title}
      </Text>
      <Flex
        onClick={() => setFold(!fold)}
        cursor="pointer"
        fontSize="1.8rem"
        flexGrow="1"
        alignItems="center"
        justifyContent="flex-end"
      >
        {fold ? 
          <MdKeyboardArrowDown/>
        : <MdKeyboardArrowUp/>}
      </Flex>
    </Flex>                          
  )
}