import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex, Image, Text } from "atoms";
import { GalleryForm } from "molecules";
import { PageLayout } from "organisms";
import { getGallery, updateGallery } from "redux/actions";
import { generatePublicURL } from "utils/utilities";

export const GalleryPage = () => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  
  
  const dispatch = useDispatch();
  const { gallery } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getGallery(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (          
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color={params.row.hide ? "danger.500" : "success.500"}
            onClick={() => {                
              dispatch(updateGallery({
                id: params.row.id,
                hide: !params.row.hide
              }))
            }}
          >
            <AiFillEye />            
          </Flex>                               
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "title", headerName: "Title", minWidth: 200, flex: 1,
      renderCell: (params) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="flex-start"            
            style={{gap: "1rem"}}
          >
            <Box
              width="4rem"
              height="4rem"              
            >
              <Image
                src={generatePublicURL(params.row.thumbnail)}
                alt={params.row.title}
                layout="fill"
                objectFit="cover"
                width="100%"
                height="100%"
              />
            </Box>
            <Text
              fontSize="1.4rem"            
              color="dark.500"            
            >
              {params.row.title}
            </Text>
          </Flex>
        );
      }
    },
    {field: "images", headerName: "Images", minWidth: 150, flex: 0.5},    
  ]

  const rows = [];
  gallery?.albums?.forEach(a => {    
    rows.push({
      id: a.id,
      title: a.title,
      thumbnail: a.thumbnail,
      hide: a.hide,
      images: a?.galleryImages?.length
    });
  });

  return (
    <PageLayout
      title="Manage Albums"
      query={query}
      setQuery={setQuery}
      rows={rows}
      columns={columns} 
      pagination={true}
      resultPerPage={gallery?.resultPerPage}
      totalCount={gallery?.totalCount}
      page={page}
      setPage={setPage}
    >                   
      <Box my="1rem">   
        <GalleryForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>            
    </PageLayout>
  )
}