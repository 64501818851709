import { EventPage } from "templates";

export const Event = () => {

  document.title = "Event | ADMIN SSJU";

  return (
    <>
      <EventPage/>
    </>
  );
};

export default Event;
