import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex } from "atoms";
import { SyllabusForm } from "molecules"; 
import { PageLayout } from "organisms";
import { generatePublicURL } from "utils/utilities";
import { getNEPSyllabus, getEntranceSyllabus, updateNEPSyllabus, updateEntranceSyllabus } from "redux/actions";

export const SyllabusPage = ({
  mode
}) => {

  const [ query, setQuery ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  

  const dispatch = useDispatch();
  const { entranceSyllabus, nepSyllabus } = useSelector(state => state.app);

  let syllabus = mode === "nep" ? nepSyllabus : mode === "entrance" ? entranceSyllabus : null;

  useEffect(()=>{   
    if(mode === "nep") dispatch(getNEPSyllabus(page, query));
    else if(mode === "entrance")  dispatch(getEntranceSyllabus(page, query));    
  }, [query, page, mode, dispatch]);

  useEffect(()=>{
    if(query) setPage(1);
  }, [query, mode]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (         
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color={params.row.hide ? "danger.500" : "success.500"}
            onClick={() => {                
              mode === "nep" ? dispatch(updateNEPSyllabus(
                params.row.id,
                {hide: !params.row.hide}
              )) : dispatch(updateEntranceSyllabus(
                params.row.id,
                {hide: !params.row.hide}
              ))              
            }}
          >
            <AiFillEye />            
          </Flex>                 
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },    
    { field: "href", headerName: "Link", minWidth: 150, flex: 0.5,
      renderCell: (params) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            color="primary.500"
            cursor="pointer"
            as="a"
            href={generatePublicURL(params.row.href)}
            target="_blank"
            rel="noreferrer"
          >
            View
          </Flex>
        );
      }
     },
  ]

  const rows = [];
  syllabus?.syllabus?.forEach(s => {    
    rows.push({
      id: s.id,
      title: s.title,      
      hide: s.hide,
      href: s.href,
    });
  });

  return (
    <PageLayout
    title={mode === "nep" ? "Manage NEP Syllabus" : mode === "entrance" ? "Manage Entrance Syllabus" : ""}
    query={query}
    setQuery={setQuery}
    rows={rows}
    columns={columns} 
    pagination={true}
    resultPerPage={syllabus?.resultPerPage}
    totalCount={syllabus?.totalCount}
    page={page}
    setPage={setPage}
    >           
      <Box my="1rem">   
        <SyllabusForm
          mode={mode}
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>
           
    </PageLayout>
  )
}