import { RecruitmentPage } from "templates";

export const Recruitment = () => {

  document.title = "Recruitment | ADMIN SSJU";

  return (
    <>
      <RecruitmentPage/>
    </>
  );
};

export default Recruitment;
