import { FieldArray, Formik, Form } from "formik";
import { BsCheckLg } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Input, Text } from "atoms";
import { Fold } from "molecules";
import { createEntranceExam, updateEntranceExam, addDocToEntranceExam } from "redux/actions";
import { isoDateToHtmlInputDate } from "utils/utilities";

const validationSchema = Yup.object({
  name: Yup.string()    
    .required("Exam Name Required"),      
  year: Yup.number()
    .required("Exam Year Required"),
  applyLink: Yup.string()
    .required("Apply Link Required"),
});

export const EntranceExamForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    const form = {
      name: values.name,
      year: values.year, 
      closed: values.closed,  
      applyLink: values.applyLink,
      entranceExamDocs: values.entranceExamDocs,
      hide: values.hide
    }      
    if(values.startDate) form.startDate = values.startDate;
    if(values.endDate) form.endDate = values.endDate;
    if(values.examDate) form.examDate = values.examDate;
    if(values.admitCardDate) form.admitCardDate = values.admitCardDate;
    if(values.admitCardLink) form.admitCardLink = values.admitCardLink;
    if(type === "create"){      
      dispatch(createEntranceExam(form));      
    }else if(type === "update"){      
      dispatch(updateEntranceExam(data?.id, form));
      setFold(true);
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >     
        <Fold
          title={type === "create" ? "Create Entrance Exam" : "Update Entrance Exam"}
          fold={fold}
          setFold={setFold}
        />                       
        {!fold && <Formik
          initialValues={{
            name: data?.name || "",
            year: data?.year || "",
            applyLink: data?.applyLink || "",
            examDate: data?.examDate ? isoDateToHtmlInputDate(data?.examDate) : "",
            startDate: data?.startDate ? isoDateToHtmlInputDate(data?.startDate) : "",
            endDate: data?.endDate ? isoDateToHtmlInputDate(data?.endDate) : "",
            admitCardDate: data?.admitCardDate ? isoDateToHtmlInputDate(data?.admitCardDate) : "",
            admitCardLink: data?.admitCardLink || "",
            closed: data?.closed || false,
            hide: data?.hide || false,
            entranceExamDocs: data?.entranceExamDocs || [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  flexDirection="column"
                  style={{gap: "1rem"}}
                >
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                    style={{gap: "1rem"}}
                  >                    
                    <Input   
                      name="name"
                      type="text"
                      id="name"
                      touched={touched.name}
                      formik={true}
                      errors={errors.name}      
                      label="Name"                      
                      value={values.name}                                            
                    />                     
                    <Input   
                      name="year"
                      type="number"
                      id="year"
                      touched={touched.year}
                      formik={true}
                      errors={errors.year}
                      label="Year"
                      value={values.year}
                    />                                       
                  </Flex>                 
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                    alignItems={{md: "center"}}
                    justifyContent={{md: "space-between"}}
                    style={{gap: "1rem"}}
                  >                  
                    <Input   
                      name="applyLink"
                      type="text"
                      id="applyLink"
                      touched={touched.applyLink}
                      formik={true}
                      errors={errors.applyLink}
                      label="Apply Link"
                      value={values.applyLink}                    
                    />                  
                    <Input
                      name="examDate"
                      type="date"
                      id="examDate"
                      touched={touched.examDate}
                      formik={true}
                      errors={errors.examDate}
                      label="Exam Date"
                      value={values.examDate}                    
                    />                                    
                  </Flex>
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                    alignItems={{md: "center"}}
                    justifyContent={{md: "space-between"}}
                    style={{gap: "1rem"}}
                  >                  
                    <Input
                      name="startDate"
                      type="date"
                      id="startDate"
                      touched={touched.startDate}
                      formik={true}
                      errors={errors.startDate}
                      label="Start Date"
                      value={values.startDate}
                    />
                    <Input
                      name="endDate"
                      type="date"
                      id="endDate"
                      touched={touched.endDate}
                      formik={true}
                      errors={errors.endDate}
                      label="End Date"
                      value={values.endDate}                                           
                    />                                  
                  </Flex>
                  <Flex
                    flexDirection={{xs: "column", md: "row"}}
                    alignItems={{md: "center"}}
                    justifyContent={{md: "space-between"}}
                    style={{gap: "1rem"}}
                  >                  
                    <Input   
                      name="admitCardDate"
                      type="date"
                      id="admitCardDate"
                      touched={touched.admitCardDate}
                      formik={true}
                      errors={errors.admitCardDate}
                      label="Admit Card Date"
                      value={values.admitCardDate}                                       
                    />                   
                    <Input
                      name="admitCardLink"
                      type="text"
                      id="admitCardLink"
                      touched={touched.admitCardLink}
                      formik={true}
                      errors={errors.admitCardLink}
                      label="Admit Card Link"
                      value={values.admitCardLink}                    
                    />                   
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    style={{gap: "1.5rem"}}
                  >
                    <Button
                      type="button"
                      variant="accent"
                      width="fit-content"
                      px="2rem"
                      py="1rem"
                      borderRadius="0.5rem" 
                      onClick={() => {
                        setFieldValue("entranceExamDocs", [...values?.entranceExamDocs, {title: "", href: "" }])                      
                      }}                   
                    >
                      Add Links
                    </Button>                
                    {type==="update" && <Box>
                      <FormControlLabel
                        control={
                          <Switch                          
                            checked={values.hide}
                            onChange={(e) => {
                              setFieldValue("hide", e.target.checked);
                            }}
                            name="hide"
                            color="primary"
                          />
                        }
                        label="Hide Form?"
                      />
                    </Box>}
                    {type==="update" && <Box>
                      <FormControlLabel
                        control={
                          <Switch                          
                            checked={values.closed}
                            onChange={(e) => {
                              setFieldValue("closed", e.target.checked);
                            }}
                            name="hide"
                            color="secondary"
                          />
                        }
                        label="Form Closed?"
                      />
                    </Box>}                    
                  </Flex>                  
                </Flex>
                <Box
                  mt="2rem"
                  p="2rem"
                  border="1px solid"
                  borderColor="grey.300"
                  borderRadius="0.5rem"
                  width="100%"
                >
                  <Text>
                    Documents
                  </Text>
                  <FieldArray
                    name="entranceExamDocs"
                    render={(arrayHelpers) => (
                      <Flex 
                        mt="1rem"
                        flexDirection="column"
                        style={{gap: "1rem"}}
                      >
                        {values.entranceExamDocs?.map((item, index) => (
                          <Flex 
                            key={index}
                            flexDirection={{xs: "column", md: "row"}}
                            alignItems={{md: "center"}}                            
                            style={{gap: "1rem"}}                            
                          >                                                     
                              <Input 
                                name={`entranceExamDocs.${index}.title`}
                                type="text"
                                id={`entranceExamDocs.${index}.title`}
                                touched={touched.entranceExamDocs}
                                formik={true}
                                errors={errors.entranceExamDocs}
                                label={`Link Title ${index + 1}`}
                                value={item.title}                                                              
                              />                             
                              <Input
                                name={`entranceExamDocs.${index}.href`}
                                type="text"
                                id={`entranceExamDocs.${index}.href`}
                                touched={touched.entranceExamDocs}
                                formik={true}
                                errors={errors.entranceExamDocs}
                                label={`Link URL ${index + 1}`}
                                value={item.href}                                
                              />                             
                            {type === "update" && <Button
                              type="button"
                              variant="success"
                              width="fit-content"
                              px="2rem"
                              py="1rem"                            
                              alignItems="center"
                              justifyContent="center"                              
                              borderRadius="0.5rem"   
                              onClick={() => {
                                if(item.id){
                                  dispatch(addDocToEntranceExam(
                                    data.id,                                    
                                    {
                                      title: item.title,
                                      href: item.href,
                                      dId: item.id
                                    }                                 
                                  )); 
                                }else{
                                  dispatch(addDocToEntranceExam(
                                    data.id,                                    
                                    {
                                      title: item.title,
                                      href: item.href
                                    }                                 
                                  ));                                 
                                }
                              }}                            
                            >                           
                              <BsCheckLg/>                           
                            </Button>}                                                                               
                            {!item.id && <Button
                              type="button"
                              variant="danger"                            
                              width="fit-content"
                              px="2rem"
                              py="1rem"
                              alignItems="center"
                              justifyContent="center"                              
                              borderRadius="0.5rem"
                              onClick={() => arrayHelpers.remove(index)}                               
                            >                           
                              <MdDeleteOutline/>                        
                            </Button>}                         
                          </Flex>
                        ))}
                      </Flex>
                    )}
                  /> 
                </Box>                                                             
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}