import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import { Box, Button, Flex, Input, InputSelect } from "atoms";
import { Fold } from "molecules";
import { createCBDUser, updateCBDUser } from "redux/actions";
import Axios from "utils/axios";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Required"),
  email: Yup.string()
    .required("Required")
    .email("Invalid email"),
  role: Yup.string()
    .required("Required"),
});

export const CBDUserForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  

  const [ campuses, setCampuses ] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if(campuses?.length === 0){
        let { data } = await Axios.get("/cbd/campus");
        if(data?.data?.length > 0){
          let temp = data?.data?.map((c) => ({
            label: c?.name,
            value: c?.id,
          }))
          setCampuses(temp);
        }
      }
    }
    fetchData();
  }, [campuses]);

  const roles = [
    {label: "Admin", value: "ADMIN"},
    {label: "User", value: "USER"},
  ]
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    const form = {
      name: values.name,
      email: values.email,
      role: values.role,
      active: values.active,
    }
    if(values.phone) form.phone = values.phone;
    if(values?.campusId && values?.role === "USER") form.campusId = values.campusId;          
    if(type === "create"){      
      dispatch(createCBDUser(form));
    }else if(type === "update"){
      form.id = data?.id;
      dispatch(updateCBDUser(form));
    }
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >  
        <Fold
          title={type === "create" ? "Create User" : "Update User"}
          fold={fold}
          setFold={setFold}
        />                        
        {!fold && <Formik
          initialValues={{
            name: data?.name || "",
            email: data?.email || "",
            phone: data?.phone || "",
            role: data?.role || "",
            campusId: data?.campusId || "",  
            active: data?.id ? data?.active : true,          
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>                 
                <Input   
                  name="name"
                  type="text"
                  id="name"
                  touched={touched.name}
                  formik={true}
                  errors={errors.name}      
                  label="Name"                      
                  value={values.name}
                  mb="1rem"                  
                />                 
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                  mb="2rem"
                >                   
                  <Input   
                    name="email"
                    type="email"
                    id="email"
                    touched={touched.email}
                    formik={true}
                    errors={errors.email}
                    label="Email"
                    value={values.email}                    
                  />                  
                  <Input   
                    name="phone"
                    type="text"
                    id="phone"
                    touched={touched.phone}
                    formik={true}
                    errors={errors.phone}
                    label="Phone"
                    value={values.phone}                                        
                  />                                                            
                </Flex>                  
                <Flex 
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                >                  
                  <InputSelect   
                    value={values.role}
                    setValue={setFieldValue}
                    options={roles}
                    label="Role"                                             
                    formik={true}
                    fieldName="role"
                    touched={touched.role}
                    errors={errors.role}  
                    name="role"
                    id="role"
                    placeholder="Select Role"                 
                  />                   
                  {values?.role === "USER" && <InputSelect   
                    value={values.campusId}
                    setValue={setFieldValue}
                    options={campuses}
                    label="Campus"
                    formik={true}
                    fieldName="campusId"
                    touched={touched.campusId}
                    errors={errors.campusId}
                    name="campusId"
                    id="campusId"
                    placeholder="Select Campus"
                  />}
                  <Box>
                    <FormControlLabel
                      control={
                        <Switch                          
                          checked={values.active}
                          onChange={(e) => {
                            setFieldValue("active", e.target.checked);
                          }}
                          name="active"
                          color="secondary"
                        />
                      }
                      label="Active?"
                    />
                  </Box>                 
                </Flex>                  
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}