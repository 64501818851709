import { NewsPage } from "templates";

export const News = () => {

  document.title = "News | ADMIN SSJU";

  return (
    <>
      <NewsPage/>
    </>
  );
};

export default News;
