import { useState } from "react";

import { Box, Text } from "atoms";
import { MediaCoverageForm } from "molecules";

export const MediaPage = () => {
  
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);

  return (
    <Box>    
      <Text            
        fontSize="2rem"
        fontWeight="600"
        color="primary.500"
        textTransform="uppercase"
      >
        Manage Media Coverage
      </Text>          
      <Box my="1rem">   
        <MediaCoverageForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>            
    </Box>
  )
}