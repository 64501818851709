import { ExamFormPage } from "templates";

export const ExamForm = () => {

  document.title = "ExamForm | ADMIN SSJU";

  return (
    <>
      <ExamFormPage/>
    </>
  );
};

export default ExamForm;
