import React from "react";
import { DataGrid } from "@material-ui/data-grid";

import { Box, Flex, InputDebounce, Text } from "atoms";
import Pagination from "react-js-pagination";

export const PageLayout = ({
  title,
  query,
  setQuery,
  children,
  rows,
  columns,
  pagination=false,
  resultPerPage,
  totalCount,
  page,
  setPage
}) => {
  return (
    <Box>
      <Flex
        alignItems={{xs: "start", md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text            
          fontSize="2rem"
          fontWeight="600"
          color="primary.500"
          textTransform="uppercase"
        >
          {title}
        </Text>
        <Box>
          <InputDebounce            
            name="search"
            type="text"
            id="search"                    
            label="Search here"
            placeholder="Search here"
            value={query}          
            setValue={setQuery}
          />
        </Box>                          
      </Flex> 
      {children}
      <Box my="1rem">   
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rows.length}
          disableSelectionOnClick                
          autoHeight
          style={{ backgroundColor: "white" }}                    
        />  
      </Box> 
      {pagination && resultPerPage < totalCount && <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={resultPerPage}
          totalItemsCount={totalCount}
          onChange={(e) => setPage(e)}
          nextPageText="Next"
          prevPageText="Prev"
          firstPageText="1st"
          lastPageText="Last"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}       
      {totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="secondary.200"
        my="2rem"
      >
        Total Entries Found : {totalCount}
      </Text>}  
    </Box>
  )
}