import { SyllabusPage } from "templates";

export const EntranceSyllabus = () => {

  document.title = "Entrance Syllabus | ADMIN SSJU";

  return (
    <>
      <SyllabusPage
        mode="entrance"
      />
    </>
  );
};

export default EntranceSyllabus;
