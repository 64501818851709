import { FieldArray, Formik, Form } from "formik";
import { BsCheckLg } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Input } from "atoms";
import { Fold } from "molecules";
import { createResult, createResultCourse, updateResultCourse } from "redux/actions";

export const ResultForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
    
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);  

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){                  
      const form = {
        title: values.title,        
      }            
      dispatch(createResult(form));      
    }else if(type === "update"){      
    }        
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >
        <Fold
          title={type === "create" ? "Create Result" : "Update Result"}
          fold={fold}
          setFold={setFold}
        />          
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            resultCourses: data?.resultCourses || [],
          }}          
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, resetForm, values, setFieldValue }) => {
            return (
              <Form>                
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                > 
                  <Box flexGrow="1">
                    <Input   
                      name="title"
                      type="text"
                      id="title"
                      touched={touched.title}
                      formik={true}
                      errors={errors.title}      
                      label="Title"
                      value={values.title}                                               
                      disabled={type === "update"}                      
                    /> 
                  </Box>
                  {type==="update" && <Button
                    type="button"
                    variant="secondary"
                    width="fit-content"
                    px="2rem"
                    py="1rem"
                    borderRadius="0.5rem" 
                    onClick={() => {
                      setFieldValue("resultCourses", [...values?.resultCourses, {title: "", href: "", hide: false }])                      
                    }}                   
                  >
                    Add Course
                  </Button>}                  
                </Flex>
                <FieldArray
                  name="resultCourses"
                  render={(arrayHelpers) => (
                    <Flex 
                      mt="1.5rem"
                      flexDirection="column"
                      style={{gap: "1rem"}}
                    >
                      {values.resultCourses?.map((item, index) => (
                        <Flex 
                          key={index}
                          flexDirection={{xs: "column", md: "row"}}
                          alignItems={{md: "center"}}                    
                          style={{gap: "1rem"}}
                        >                                                   
                          <Input
                            name={`resultCourses.${index}.title`}
                            type="text"
                            id={`resultCourses.${index}.title`}
                            touched={touched.resultCourses}
                            formik={true}
                            errors={errors.resultCourses}      
                            label={`Course Name ${index + 1}`}
                            value={values.resultCourses[index].title}                                                                                                                                                                   
                          />                           
                          <Input
                            name={`resultCourses.${index}.href`}
                            type="text"
                            id={`resultCourses.${index}.href`}
                            touched={touched.resultCourses}
                            formik={true}
                            errors={errors.resultCourses}      
                            label={`Result Link ${index + 1}`}
                            value={values.resultCourses[index].href}                            
                          />                          
                          {item.id && <Box>                          
                            <FormControlLabel
                              control={
                                <Switch                          
                                  checked={item.hide}
                                  onChange={(e) => {
                                    setFieldValue(`resultCourses.${index}.hide`, e.target.checked)
                                  }}
                                  name={`resultCourses.${index}.hide`}
                                  color="secondary"
                                />
                              }
                              label="Hide Result?"
                            />                  
                          </Box>}                                                   
                          {type==="update" && <Button
                            type="button"
                            variant="success"
                            width="fit-content"
                            px="2rem"
                            py="1rem"                            
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"   
                            onClick={() => {
                              if(item.id){
                                dispatch(updateResultCourse({
                                  id: item.id,
                                  title: item.title,
                                  href: item.href,
                                  hide: item.hide,                                  
                                }));                                
                              }else{
                                dispatch(createResultCourse({
                                  resultId: data.id,
                                  title: item.title,
                                  href: item.href,                                  
                                }));                                 
                              }
                            }}                            
                          >                           
                            <BsCheckLg/>                           
                          </Button>}
                          {type==="update" && !item.id && <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>}                         
                        </Flex>
                      ))}
                    </Flex>
                  )}
                />                    
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}
                    disabled={type === "update"}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}