import Modal from "react-modal";

import { Box, Flex, Text } from "atoms";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    padding: "0px",
    border: "none",    
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "6"
  },
};

export const DeleteConfirmationModal = ({ isOpen, onRequestClose, ariaHideApp, data, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={ariaHideApp}      
    >
      <Box
        width="100%"
        maxWidth={{xs: "30rem", md: "60rem"}}
        bg="white"
        px={{xs: "1rem", md: "3rem"}}        
      >        
        <Box p="2rem" mb="2rem">
          <Text
            fontWeight="500"
            fontSize="1.6rem"            
            color="black"            
            textAlign="start"            
          >
            Are you sure you want to delete this {data.name}{" "}({data.module}) ?
          </Text>          
        </Box>
        
        <Flex
          alignItems="center"   
          borderTop="1px solid #E5E5E5"
        >
          <Box
            flex="1"
            textAlign="center"
            p="1rem 2rem"
            fontSize="1.6rem"
            color="black"
            cursor="pointer"
            onClick={onRequestClose}
          >
            Cancel
          </Box>         
          <Box
            flex="1"
            textAlign="center"
            p="1rem 2rem"
            fontSize="1.6rem"
            color="danger.100"
            borderLeft="1px solid #E5E5E5"
            cursor="pointer"
            onClick={onConfirm}
          >
            Delete
          </Box>            
        </Flex>
      </Box>
    </Modal>
  )
}