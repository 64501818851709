import { FieldArray, Formik, Form } from "formik";
import { BsCheckLg } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Flex, Input } from "atoms";
import { Fold } from "molecules";
import { createCampus, createCampusCollege, updateCampusCollege } from "redux/actions";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Required"),
  email: Yup.string()
    .required("Required")
    .email("Invalid email"),
});

export const CampusForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      const form = {
        name: values.name,
        email: values.email,
      }
      if(values.phone) form.phone = values.phone;
      dispatch(createCampus(form));
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >
        <Fold
          title={type === "create" ? "Create Campus" : "Update Campus"}
          fold={fold}
          setFold={setFold}
        />
        {!fold && <Formik
          initialValues={{
            name: data?.name || "",
            email: data?.email || "",
            phone: data?.phone || "",
            colleges: data?.colleges || [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>                 
                <Input   
                  name="name"
                  type="text"
                  id="name"
                  touched={touched.name}
                  formik={true}
                  errors={errors.name}      
                  label="Name"                      
                  value={values.name}
                  mb="1rem"
                />                 
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems="center"
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                >                   
                  <Input   
                    name="email"
                    type="email"
                    id="email"
                    touched={touched.email}
                    formik={true}
                    errors={errors.email}      
                    label="Email"                      
                    value={values.email}
                  />                   
                  <Input   
                    name="phone"
                    type="text"
                    id="phone"
                    touched={touched.phone}
                    formik={true}
                    errors={errors.phone}      
                    label="Phone"                      
                    value={values.phone}
                  /> 
                  {type==="update" && <Button
                    type="button"
                    variant="secondary"
                    width="fit-content"                    
                    px="2rem"
                    py="1rem"                    
                    borderRadius="0.5rem" 
                    onClick={() => {
                      setFieldValue(
                        "colleges", 
                        [...values?.colleges, {name: "", email: "", phone: "", campusId: data.id }
                      ])                      
                    }}                   
                  >
                    Add College
                  </Button>}                              
                </Flex>                
                <FieldArray
                  name="colleges"
                  render={(arrayHelpers) => (
                    <Flex
                      mt="1rem"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="flex-start"                      
                      style={{gap: "1rem"}}                      
                    >
                      {values.colleges?.map((item, index) => (
                        <Flex 
                          key={index}
                          flexDirection={{xs: "column", md: "row"}}
                          alignItems={{md: "center"}}                  
                          style={{gap: "1rem"}}                                                    
                          width="100%"
                        >                                                                                   
                          <Input
                            name={`colleges.${index}.name`}
                            type="text"
                            id={`colleges.${index}.name`}
                            touched={touched.colleges}
                            formik={true}
                            errors={errors.colleges}      
                            label={`College Name ${index + 1}`}
                            value={item.name}
                          />                         
                          <Input
                            name={`colleges.${index}.email`}
                            type="email"
                            id={`colleges.${index}.email`}
                            touched={touched.colleges}
                            formik={true}
                            errors={errors.colleges}
                            label={`College Email ${index + 1}`}
                            value={item.email}                              
                          />                         
                          <Input
                            name={`colleges.${index}.phone`}
                            type="text"
                            id={`colleges.${index}.phone`}
                            touched={touched.colleges}
                            formik={true}
                            errors={errors.colleges}
                            label={`College Phone ${index + 1}`}
                            value={item.phone}
                          />                          
                          {type==="update" && <Button
                            type="button"
                            variant="success"
                            width="fit-content"
                            px="2rem"
                            py="1rem"                            
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"   
                            onClick={() => {
                              if(item.id){
                                dispatch(updateCampusCollege({
                                  id: item.id,
                                  name: item.name,
                                  email: item.email,
                                  phone: item.phone,
                                  campusId: data.id,
                                }));                                
                              }else{
                                dispatch(createCampusCollege({
                                  name: item.name,
                                  email: item.email,
                                  phone: item.phone,
                                  campusId: data.id,                                  
                                })); 
                                setData(null);
                                setType("create");
                                resetForm();
                              }
                            }}                            
                          >                           
                            <BsCheckLg/>                           
                          </Button>}
                          {type==="update" && !item.id && <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>}                         
                        </Flex>
                      ))}
                    </Flex>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}