import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex } from "atoms";
import { NewsForm, DeleteConfirmationModal } from "molecules";
import { PageLayout } from "organisms";
import { getNews, deleteNews, updateNews } from "redux/actions";
import { isoDateToHtmlInputDate } from "utils/utilities";

export const NewsPage = () => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);
  const [ forDelete, setForDelete ] = useState(null);
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  
  const dispatch = useDispatch();
  const { news } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getNews(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (          
          <Flex
            style={{gap: "1rem"}}
          >
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color={params.row.hide ? "danger.500" : "success.500"}
              onClick={() => {                
                dispatch(updateNews({
                  id: params.row.id,
                  hide: !params.row.hide
                }))
              }}
            >
              <AiFillEye />            
            </Flex>
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color="danger.500"
              onClick={() => {
                setForDelete({
                  id: params.row.id,
                  name: params.row.title
                });
                setDeleteModalOpen(true);
              }}
            >
              <MdDeleteOutline />            
            </Flex>
          </Flex>          
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "title", headerName: "Title", minWidth: 200, flex: 1},
    {field: "added", headerName: "Added On", minWidth: 150, flex: 0.5},    
  ]

  const rows = [];
  news?.news?.forEach(n => {    
    rows.push({
      id: n.id,
      title: n.title,
      hide: n.hide,
      added: isoDateToHtmlInputDate(n.createdAt),
    });
  });

  return (
    <PageLayout
      title="Manage Exam Schedule"
      query={query}
      setQuery={setQuery}
      rows={rows}
      columns={columns} 
      pagination={true}
      resultPerPage={news?.resultPerPage}
      totalCount={news?.totalCount}
      page={page}
      setPage={setPage}
    > 
      {deleteModalOpen && 
        <DeleteConfirmationModal
          isOpen={deleteModalOpen}
          data={{
            name: forDelete.name,
            module: "News",
          }}            
          onRequestClose={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          ariaHideApp={() => {              
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
          onConfirm={() => {
            dispatch(deleteNews(forDelete?.id))
            setDeleteModalOpen(false);
            setForDelete(null);
          }}
        />
      }                 
      <Box my="1rem">   
        <NewsForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>          
    </PageLayout>
  )
}