import { AiOutlineTool } from "react-icons/ai";
import { Link } from "react-router-dom";

import { Box, Flex, Text } from "atoms";
import { sideNav } from "organisms/Sidebar/data";

export const DashboardPage = () => {
  return (
    <>
      <Flex
        width="100%"        
        p="2rem"
        flexWrap="wrap"                             
      > 
      {sideNav?.map((item, index) => (
        <Link to={item.to}>
          <Box 
            key={index}            
            bg="white"
            width="fit-content"
            borderRadius="0.5rem"
            boxShadow="0 0 0.5rem 0.1rem rgba(0,0,0,0.1)"
            mr="2rem"
            mb="2rem"
            p="2rem"
          >
            <Flex
              mb="2rem"
              fontSize="2rem"
              color="grey.100"
              alignItems="center"
              justifyContent="center"
            >
              {item.Icon}
            </Flex>
            <Text
              fontSize="1.4rem"
              fontWeight="500"
              textAlign="center"
            >
              {item.title}
            </Text>
            <Text
              fontSize="1rem"
              color="grey.100"
              mt="0.5rem"              
            >
              {item.description}
            </Text>
          </Box>
        </Link>
      ))}
      <Link to="/tools">
        <Box            
          bg="white"
          width="fit-content"
          borderRadius="0.5rem"
          boxShadow="0 0 0.5rem 0.1rem rgba(0,0,0,0.1)"
          mr="2rem"
          mb="2rem"
          p="2rem"
        >
          <Flex
            mb="2rem"
            fontSize="2rem"
            color="grey.100"
            alignItems="center"
            justifyContent="center"
          >
            <AiOutlineTool/>
          </Flex>
          <Text
            fontSize="1.4rem"
            fontWeight="500"
            textAlign="center"
          >
            Tools
          </Text>
          <Text
            fontSize="1rem"
            color="grey.100"
            mt="0.5rem"              
          >
            View all tools
          </Text>
        </Box>
      </Link>
      </Flex>      
    </>
  )
}