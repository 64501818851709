import Compress from "compress.js";

import Axios from "./axios";
import { config } from "config";
import { sideNav } from "organisms/Sidebar/data";

export const isMobile = () => (typeof window !== "undefined" ? window.innerWidth <= 600 : false);
export const isTab = () => (typeof window !== "undefined" ? window.innerWidth <= 1024 : false);

export const scrollToTop = (window) =>{
  window.scrollTo({top: 0, left: 0, behavior: "smooth" })
}    

export const getAssetURL = (filename) => {
  return "/assets/"+filename;
}

export const generatePublicURL = (file) => {
  if(file.includes("http") || file.includes("https")) return file;
  if(file.split("/").length > 1) return file;
  return config.MEDIA_URL + "/" + file
}

export const checkPermission = (name, action, userPermission) => {  
  const moduleIndex = userPermission.findIndex(item => item.module.name === name);
  if(moduleIndex < 0){
    return false;
  }
  const isAuthorized = userPermission[moduleIndex].actions[action-1];
  return isAuthorized;
}

export const capitalizeWord = (word) => {  
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export const printINR= (cost) => {
  return new Intl.NumberFormat("en-IN", {style: "currency", currency: "INR"}).format(cost)
}

export const isoDateToHtmlInputDate= (date) => {
  let _date = new Date(date);
  return _date.toLocaleDateString("sv-SE");
}

export const htmlInputDateToIsoDate= (date) => {
  let _date = new Date(date);
  return _date.toISOString();
}

export const getISODate = (date, time) => {  
  if(time === "start"){
    return new Date(date).toISOString().split("T")[0] + "T00:00:00.000Z";
  }else if(time === "end"){
    return new Date(date).toISOString().split("T")[0] + "T23:59:59.999Z";
  }
}

export const checkPageAccess = (userRole, page) => {
  const _page = sideNav.find(item => item.to === page);
  if(_page){
    return _page.authorized.includes(userRole);
  }
  return false;
}

export const resizeImageFn = async(file) => {
  const compress = new Compress()
  const resizedImage = await compress.compress([file], {
    size: 0.1, // the max size in MB, defaults to 2MB
    quality: 1, // the quality of the image, max is 1,
    maxWidth: 200, // the max width of the output image, defaults to 1920px
    maxHeight: 200, // the max height of the output image, defaults to 1920px
    resize: true // defaults to true, set false if you do not want to resize the image width and height
  })
  const img = resizedImage[0];
  const base64str = img.data
  const imgExt = img.ext
  const resizedFile = Compress.convertBase64ToFile(base64str, imgExt)
  return resizedFile;
}

export const upload = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  const { data } = await Axios.post("/uploads", formData);
  return data?.data;  
}