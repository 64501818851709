import { useEffect, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex } from "atoms";
import { CampusForm } from "molecules";
import { PageLayout } from "organisms";
import { getCampuses } from "redux/actions";

export const CampusPage = () => {

  const [ query, setQuery ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  
  
  const dispatch = useDispatch();
  const { campuses } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getCampuses(query))
  }, [query, dispatch]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (                   
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color="warning.500"
            onClick={() => {                
              setType("update");
              setData(campuses?.find(i => i.id === params.row.id));
              setFold(false);
            }}
          >
            <MdModeEdit />            
          </Flex>                              
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "name", headerName: "Name", minWidth: 200, flex: 1},
    {field: "college", headerName: "Colleges", minWidth: 150, flex: 0.5},    
  ]

  const rows = [];
  campuses?.forEach(i => {    
    rows.push({
      id: i.id,
      name: i.name,
      college: i.colleges?.length,
    });
  });

  return (
    <PageLayout
      title="Campuses"
      query={query}
      setQuery={setQuery}
      rows={rows}
      columns={columns}            
      totalCount={campuses?.length}
      page={1}
      setPage={()=>{}}
    >      
      <Box my="1rem">   
        <CampusForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>              
    </PageLayout>
  )
}