import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";

import { Box } from "atoms";

export const InputDebounce = ({
  name,
  type,    
  id,    
  disabled=false,  
  label,   
  value,
  setValue,
  mb,
  debounceTimeout=600,
  minLength=3,
}) => {

  const [isFocused, setIsFocused] = useState(false); 

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      setIsFocused(false);
    }
  };

  return (    
    <Box width="100%" mb={mb || "0"}>
      <Box className="input-container">
        <DebounceInput 
          name={name}         
          type={type}
          id={id}                              
          onWheel={(e) => e.target.blur()}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}                    
          value={value}          
          onChange={e => setValue(e.target.value)} 
          debounceTimeout={debounceTimeout}
          minLength={minLength}
          className={`input-field ${isFocused ? "input-field--focused" : ""}`}
        />    
        {label && <label
          className={`input-label ${(isFocused || value) ? "input-label--focused" : ""}`}
        >
          {label}
        </label>}       
      </Box>            
    </Box>    
  )
};