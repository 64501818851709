import { useEffect, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex } from "atoms";
import { EntranceExamForm } from "molecules";
import { PageLayout } from "organisms";
import { getEntranceExam } from "redux/actions";
import { isoDateToHtmlInputDate } from "utils/utilities";

export const EntranceExamPage = () => {

  const [ page, setPage ] = useState(1);
  const [ query, setQuery ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  
  
  const dispatch = useDispatch();
  const { entranceExam } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getEntranceExam(page, query))
  }, [page, query, dispatch]);

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (                    
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color="danger.500"
            onClick={() => {
              setType("update");
              setData(entranceExam?.exams?.find(i => i.id === params.row.id));
              setFold(false);
            }}
          >
            <MdModeEdit />            
          </Flex>         
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "id", headerName: "ID", minWidth: 100, flex: 1},
    {field: "name", headerName: "Exam Name", minWidth: 200, flex: 1},
    {field: "startDate", headerName: "Start Date", minWidth: 150, flex: 0.5},    
    {field: "endDate", headerName: "End Date", minWidth: 150, flex: 0.5},    
  ]

  const rows = [];
  entranceExam?.exams?.forEach(f => {    
    rows.push({
      id: f.id,
      name: f.name,
      hide: f.hide,
      startDate: f.startDate ? isoDateToHtmlInputDate(f.startDate) : "",
      endDate: f.endDate ? isoDateToHtmlInputDate(f.endDate) : "",
    });
  });

  return (
    <PageLayout
      title="Manage Entrance Exams"
      query={query}
      setQuery={setQuery}
      rows={rows}
      columns={columns} 
      pagination={true}
      resultPerPage={entranceExam?.resultPerPage}
      totalCount={entranceExam?.totalCount}
      page={page}
      setPage={setPage}
    >                     
      <Box my="1rem">   
        <EntranceExamForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>           
    </PageLayout>
  )
}