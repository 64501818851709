import { useState } from "react";
import { Formik, Form } from "formik";
import {  MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, Loader } from "atoms";
import { Fold } from "molecules";
import { createGallery } from "redux/actions";
import { upload } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),        
});

export const GalleryForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  

  const [ thumbnail, setThumbnail ] = useState(null);
  const [ images, setImages ] = useState([]);
  const [ localLoading, setLocalLoading ] = useState(false);
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      setLocalLoading(true);
      let thumb = await upload(thumbnail);
      const form = {
        title: values.title,
        thumbnail: thumb,
      }
      let _images = [];
      for(let i = 0; i < images?.length; i++){
        let url = await upload(images[i]);
        _images.push(url);
      }
      setLocalLoading(false);
      form.images = _images;
      dispatch(createGallery(form));
    } 
    setThumbnail(null);
    setImages([]);   
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <> 
      {localLoading && <Loader/>}     
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >
        <Fold
          title={type === "create" ? "Create Gallery" : "Update Gallery"}
          fold={fold}
          setFold={setFold}
        />                                 
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",            
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, resetForm, values }) => {
            return (
              <Form>  
                <Flex                  
                  flexDirection={{xs: "column-reverse", md: "row"}}
                  alignItems="start"
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                > 
                  <Box flexGrow="1">
                    <Input
                      name="title"
                      type="text"
                      id="title"
                      touched={touched.title}
                      formik={true}
                      errors={errors.title}
                      label="Title"
                      value={values.title}                                           
                    />
                    <Box mt="1rem">
                      <Button
                        as="label"
                        htmlFor="images"
                        variant="warning"                        
                        type="button"
                        p="0.5rem 1rem"                      
                        width="fit-content"
                        borderRadius="0.5rem"                      
                      >
                        Pick Album Images
                      </Button>
                      <input
                        type="file"
                        multiple
                        id="images"
                        name="images"
                        accept="image/*"                      
                        onChange={(e) => {
                          setImages([...e.target.files, ...images]);
                        }}
                        style={{
                          display: "none",
                        }}
                      />
                    </Box>    
                  </Box>  
                  <Flex                    
                    flex="0.5"
                    alignItems={{md: "center"}}
                    flexDirection="column"                   
                  >                        
                    {thumbnail && <Box
                      width="10rem"
                      height="10rem"
                      mb="1rem"
                    >
                      <Image
                        src={URL.createObjectURL(thumbnail)}
                        width="100%"
                        height="100%"                        
                        objectFit="cover"
                        borderRadius="0.5rem"
                        alt="image"
                      />
                    </Box>}
                    <Button
                      as="label"
                      htmlFor="image"
                      variant="info"                      
                      type="button"
                      p="0.5rem 1rem"                      
                      width="fit-content"
                      borderRadius="0.5rem" 
                      mb={{xs: "0.5rem", md: "unset"}}
                    >
                      Pick Thumbnail
                    </Button>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"                      
                      onChange={(e) => {
                        setThumbnail(e.target.files[0]);
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                  </Flex>                
                </Flex>                     
                <Flex
                  my="1.5rem"
                  flexWrap="wrap"
                  style={{gap: "1rem"}}                  
                >
                  {images?.map((image, index) => (
                    <Box>
                      <Image
                        src={URL.createObjectURL(image)}
                        width="10rem"
                        height="10rem"
                        objectFit="cover"
                        borderRadius="0.5rem"
                        alt={"image-" + index}
                      />
                      <Box
                        color="danger.100"
                        fontSize="1.8rem"
                        cursor="pointer"
                        my="1rem"
                        textAlign="center"
                      >
                        <MdDeleteOutline
                          onClick={() => {
                            const _images = [...images];
                            _images.splice(index, 1);
                            setImages(_images);
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Flex>       
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}