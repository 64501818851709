import { FieldArray, Formik, Form } from "formik";
import { BsCheckLg } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Input, Text } from "atoms";
import { Fold } from "molecules";
import { createExamForm, updateExamForm, updateExamFormDetail } from "redux/actions";
import { isoDateToHtmlInputDate } from "utils/utilities";

const validationSchema = Yup.object({
  name: Yup.string()    
    .required("Exam Name Required"),      
  year: Yup.number()
    .required("Exam Year Required"),
});

export const ExamForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      const form = {
        name: values.name,
        year: values.year, 
        closed: values.closed,  
        examFormLinks: values.examFormLinks,  
        examFormFees: values.examFormFees,
      }      
      if(values.startDate) form.startDate = values.startDate;
      if(values.endDate) form.endDate = values.endDate;
      dispatch(createExamForm(form));      
    }else if(type === "update"){
      const form = {
        closed: values.closed,
        hide: values.hide,
      }            
      if(values.startDate) form.startDate = values.startDate;
      if(values.endDate) form.endDate = values.endDate;
      dispatch(updateExamForm(data?.id, form));
      setFold(true);
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      > 
        <Fold
          title={type === "create" ? "Create Exam Form" : "Update Exam Form" }
          fold={fold}
          setFold={setFold}
        />
        {!fold && <Formik
          initialValues={{
            name: data?.name || "",
            year: data?.year || "",
            startDate: data?.startDate ? isoDateToHtmlInputDate(data?.startDate) : "",
            endDate: data?.endDate ? isoDateToHtmlInputDate(data?.endDate) : "",
            closed: data?.closed || false,
            hide: data?.hide || false,
            examFormLinks: data?.examFormLinks || [],
            examFormFees: data?.examFormFees || [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                  mb="1rem"
                >                   
                    <Input   
                      name="name"
                      type="text"
                      id="name"
                      touched={touched.name}
                      formik={true}
                      errors={errors.name}      
                      label="Exam Name*"
                      value={values.name}                      
                    />                   
                    <Input   
                      name="year"
                      type="number"
                      id="year"
                      touched={touched.year}
                      formik={true}
                      errors={errors.year}
                      label="Exam Year*"
                      value={values.year}                      
                    />
                </Flex>
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}                 
                  style={{gap: "1rem"}}
                  mb="1rem"
                >
                  <Button
                    type="button"
                    variant="info"
                    width="fit-content"
                    px="2rem"
                    py="1rem"
                    borderRadius="0.5rem" 
                    onClick={() => {
                      setFieldValue("examFormLinks", [...values?.examFormLinks, {title: "", href: "" }])                      
                    }}                   
                  >
                    Add Links
                  </Button>
                  <Button
                    type="button"
                    variant="accent"
                    width="fit-content"
                    px="2rem"
                    py="1rem"
                    borderRadius="0.5rem" 
                    onClick={() => {
                      setFieldValue("examFormFees", [...values?.examFormFees, {name: "", fee: "" }])                      
                    }}                   
                  >
                    Add Course Fee Details
                  </Button>
                  {type==="update" && <Box>
                    <FormControlLabel
                      control={
                        <Switch                          
                          checked={values.hide}
                          onChange={(e) => {
                            setFieldValue("hide", e.target.checked);
                          }}
                          name="hide"
                          color="primary"
                        />
                      }
                      label="Hide Form?"
                    />
                  </Box>}
                  {type==="update" && <Box>
                    <FormControlLabel
                      control={
                        <Switch                          
                          checked={values.closed}
                          onChange={(e) => {
                            setFieldValue("closed", e.target.checked);
                          }}
                          name="hide"
                          color="secondary"
                        />
                      }
                      label="Form Closed?"
                    />
                  </Box>}                                    
                </Flex>  
                <Flex 
                  mt="1rem" 
                  style={{gap: "2rem"}}
                  flexDirection={{xs: "column", xm: "row"}}                  
                >
                  <Box
                    p="2rem"
                    border="1px solid"
                    borderColor="grey.300"
                    borderRadius="0.5rem"
                    width="100%"
                  >
                    <Text>
                      Links
                    </Text>
                    <FieldArray
                      name="examFormLinks"
                      render={(arrayHelpers) => (
                        <Flex 
                          mt="1rem"
                          flexDirection="column"
                          style={{gap: "1rem"}}
                        >
                          {values.examFormLinks?.map((item, index) => (
                            <Flex 
                              key={index}
                              flexDirection={{xs: "column", md: "row"}}
                              alignItems={{md: "center"}}                              
                              style={{gap: "1rem"}}
                            >                                                       
                              <Input
                                name={`examFormLinks.${index}.title`}
                                type="text"
                                id={`examFormLinks.${index}.title`}
                                touched={touched.examFormLinks}
                                formik={true}
                                errors={errors.examFormLinks}
                                label={`Link Title ${index + 1}`}
                                value={values.examFormLinks[index].title} 
                              />                                                            
                              <Input
                                name={`examFormLinks.${index}.href`}
                                type="text"
                                id={`examFormLinks.${index}.href`}
                                touched={touched.examFormLinks}
                                formik={true}
                                errors={errors.examFormLinks}
                                label={`Link URL ${index + 1}`}
                                value={values.examFormLinks[index].href}                                  
                              />                                
                              {type === "update" && <Button
                                type="button"
                                variant="success"
                                width="fit-content"
                                px="2rem"
                                py="1rem"                            
                                alignItems="center"
                                justifyContent="center"                              
                                borderRadius="0.5rem"   
                                onClick={() => {
                                  if(item.id){
                                    dispatch(updateExamFormDetail(
                                      data.id,
                                      "link",
                                      {
                                        title: item.title,
                                        href: item.href,
                                        fId: item.id
                                      }                                 
                                    )); 
                                  }else{
                                    dispatch(updateExamFormDetail(
                                      data.id,
                                      "link",
                                      {
                                        title: item.title,
                                        href: item.href
                                      }                                 
                                    ));                                 
                                  }
                                }}                            
                              >                           
                                <BsCheckLg/>                           
                              </Button>}                                                                               
                              {!item.id && <Button
                                type="button"
                                variant="danger"                            
                                width="fit-content"
                                px="2rem"
                                py="1rem"
                                alignItems="center"
                                justifyContent="center"                              
                                borderRadius="0.5rem"
                                onClick={() => arrayHelpers.remove(index)}                               
                              >                           
                                <MdDeleteOutline/>                        
                              </Button>}                         
                            </Flex>
                          ))}
                        </Flex>
                      )}
                    /> 
                  </Box>
                  <Box
                    p="2rem"
                    border="1px solid"
                    borderColor="grey.300"
                    borderRadius="0.5rem"
                    width="100%"
                  >
                    <Text>
                      Course Fee
                    </Text>
                    <FieldArray
                      name="examFormFees"
                      render={(arrayHelpers) => (
                        <Flex 
                          mt="1rem"
                          flexDirection="column"
                          style={{gap: "1rem"}}
                        >
                          {values.examFormFees?.map((item, index) => (
                            <Flex 
                              key={index}
                              flexDirection={{xs: "column", md: "row"}}
                              alignItems={{md: "center"}}                  
                              style={{gap: "1rem"}}                              
                            >                                                       
                              <Input
                                name={`examFormFees.${index}.name`}
                                type="text"
                                id={`examFormFees.${index}.name`}
                                touched={touched.examFormFees}
                                formik={true}
                                errors={errors.examFormFees}
                                label={`Course Name ${index + 1}`}
                                value={values.examFormFees[index].name}                                                                
                              />                               
                              <Input
                                name={`examFormFees.${index}.fee`}
                                type="text"
                                id={`examFormFees.${index}.fee`}
                                touched={touched.examFormFees}
                                formik={true}
                                errors={errors.examFormFees}
                                label={`Course Fee ${index + 1}`}
                                value={values.examFormFees[index].fee}                                
                              />                              
                              {type === "update" && <Button
                                type="button"
                                variant="success"
                                width="fit-content"
                                px="2rem"
                                py="1rem"                            
                                alignItems="center"
                                justifyContent="center"                              
                                borderRadius="0.5rem"   
                                onClick={() => {
                                  if(item.id){
                                    dispatch(updateExamFormDetail(
                                      data.id,
                                      "fee",
                                      {
                                        name: item.name,
                                        fee: item.fee,
                                        fId: item.id
                                      }                                 
                                    )); 
                                  }else{
                                    dispatch(updateExamFormDetail(
                                      data.id,
                                      "fee",
                                      {
                                        name: item.name,
                                        fee: item.fee
                                      }                                 
                                    ));                                 
                                  }
                                }}                            
                              >                           
                                <BsCheckLg/>                           
                              </Button>}                                                                               
                              {!item.id && <Button
                                type="button"
                                variant="danger"                            
                                width="fit-content"
                                px="2rem"
                                py="1rem"
                                alignItems="center"
                                justifyContent="center"                              
                                borderRadius="0.5rem"
                                onClick={() => arrayHelpers.remove(index)}                               
                              >                           
                                <MdDeleteOutline/>                        
                              </Button>}                         
                            </Flex>
                          ))}
                        </Flex>
                      )}
                    /> 
                  </Box>
                </Flex>                                           
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}