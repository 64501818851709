import { useEffect, useState } from "react";
import { AiFillEye, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex } from "atoms";
import { ExamScheduleForm } from "molecules"; 
import { PageLayout } from "organisms";
import { getExamSchedule, updateExamSchedule } from "redux/actions";
import { generatePublicURL } from "utils/utilities";

export const ExamSchedulePage = () => {

  const [ query, setQuery ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  

  const dispatch = useDispatch();
  const { examSchedule } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getExamSchedule(page, query))
  }, [query, page, dispatch]);

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return ( 
          <Flex
            style={{ gap: "1rem" }}
            alignItems="center"
          >
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color={params.row.hide ? "danger.500" : "success.500"}
              onClick={() => {                
                dispatch(updateExamSchedule({
                  id: params.row.id,
                  hide: !params.row.hide
                }))
              }}
            >
              <AiFillEye />            
            </Flex>   
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color="warning.500"
              onClick={() => {                
                setData(params.row);
                setType("update");
                setFold(false);                
              }}
            >
              <AiOutlineEdit />
            </Flex>              
          </Flex>
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },    
    { field: "href", headerName: "Link", minWidth: 150, flex: 0.5,
      renderCell: (params) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="center"
            color="primary.500"
            cursor="pointer"
            as="a"
            href={generatePublicURL(params.row.href)}
            target="_blank"
            rel="noreferrer"
          >
            View
          </Flex>
        );
      }
     },
  ]

  const rows = [];
  examSchedule?.schedules?.forEach(e => {    
    rows.push({
      id: e.id,
      title: e.title,      
      hide: e.hide,
      href: e.href,
    });
  });

  return (
    <PageLayout
      title="Manage Exam Schedule"
      query={query}
      setQuery={setQuery}
      rows={rows}
      columns={columns} 
      pagination={true}
      resultPerPage={examSchedule?.resultPerPage}
      totalCount={examSchedule?.totalCount}
      page={page}
      setPage={setPage}
    >                  
      <Box my="1rem">   
        <ExamScheduleForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>        
    </PageLayout>
  )
}