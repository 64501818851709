import { useEffect, useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex } from "atoms";
import { CBDUserForm } from "molecules";
import { PageLayout } from "organisms";
import { getCBDUsers } from "redux/actions";

export const CBDUsersPage = () => {
  
  const [ query, setQuery ] = useState("");
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  
  
  const dispatch = useDispatch();
  const { cBDUsers } = useSelector(state => state.app);  

  useEffect(()=>{
    dispatch(getCBDUsers(query))
  }, [query, dispatch]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (                   
          <Flex
            fontSize="2rem"            
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            color="warning.500"
            onClick={() => {                
              setType("update");
              setData(cBDUsers?.find(i => i.id === params.row.id));
              setFold(false);
            }}
          >
            <MdModeEdit />            
          </Flex>                              
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    {field: "name", headerName: "Name", minWidth: 200, flex: 1},
    {field: "email", headerName: "Email", minWidth: 200, flex: 1},
    {field: "role", headerName: "Role", minWidth: 150, flex: 0.5},    
    {field: "campus", headerName: "Campus", minWidth: 200, flex: 1},
  ]

  const rows = [];
  cBDUsers?.forEach(i => {    
    rows.push({
      id: i.id,
      name: i.name,
      email: i.email,
      role: i.role,
      campus: i?.campus?.name || "",
    });
  });

  return (
    <PageLayout
      title="Manage CBD Users"
      query={query}
      setQuery={setQuery}
      rows={rows}
      columns={columns}
      totalCount={cBDUsers?.length}
      page={1}
      setPage={()=>{}}
    >                   
      <Box my="1rem">   
        <CBDUserForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>                
    </PageLayout>
  )
}