import { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";

import { Box, Flex } from "atoms";
import { Header, Footer, Sidebar } from "organisms";
import { scrollToTop } from "utils/utilities";

export const Layout = ({
  children
}) => {

  const [scroll, setScroll] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 200) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Box
      maxWidth="100%"
      maxHeight="100vh"
    >
      <Header/>
      <Flex>
        <Sidebar/>
        <Box 
          flex="4"
          position="sticky"
          top="5rem"          
          maxHeight="calc(100vh - 8rem)"
          bg="#F5F5F5"
          p="2rem"
          overflowY="auto"
        >
          {children}
        </Box>        
      </Flex>
      <Footer/>
      {scroll && (
        <Box
          position="fixed"
          color="white"
          bg="primary.500"
          bottom="4rem"
          right="2rem"
          width="3rem"
          height="3rem"
          borderRadius="0.2rem"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={() => scrollToTop(window)}
        >
          <IoIosArrowUp size="2.5rem" />
        </Box>
      )}   
    </Box>
  )
}