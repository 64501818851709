import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { Box, Error } from "atoms";

export const Input = ({
  name,
  type,    
  id,  
  touched,
  errors,  
  rows,
  disabled=false,  
  label,    
  value,  
  formik=false,
  mb,
  as="input",
  passwordField=false,
}) => {

  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);  

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    if (event.target.value === "") {
      setIsFocused(false);
    }
  };  

  return (
    <>      
      <Box width="100%" flex="1" mb={mb || "0"}>
        <Box className="input-container">
          {formik ? <Field
            as={as}
            type={type === "password" && showPassword ? "text" : type}
            id={id}
            name={name}          
            rows={rows}
            onWheel={(e) => e.target.blur()}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}        
            className={`input-field ${(isFocused || value || type === "date") ? "input-field--focused" : ""} ${touched && errors ? "input-error" : ""}`}
            style={{
              borderColor: touched && !errors
                ? "#0BBA7A"
                : ""
            }}
          /> : <input
            as={as}
            type={type}                    
            rows={rows}
            onWheel={(e) => e.target.blur()}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}        
            className={`input-field ${(isFocused || value || type === "date") ? "input-field--focused" : ""}`}
          />}          
          {label && <label
            className={`input-label ${(isFocused || value || type === "date") ? "input-label--focused" : ""} ${as==="textarea" ? "input-label--textarea" : ""}`}
          >
            {label}
          </label>}
          {passwordField && <Box
            position="absolute"
            right="1.6rem"
            top="50%"
            transform="translateY(-50%)"
            fontSize="1.8rem"
            color="#7d7d7d"          
            onClick={() => setShowPassword(!showPassword)}
            display="flex"
            alignItems="center"
            cursor="pointer"
            justifyContent="center"
          >
            {showPassword ? <AiFillEye/>  : <AiFillEyeInvisible/>}
          </Box>}
          {formik && <ErrorMessage name={name} component={Error} />}
        </Box>            
      </Box>
    </>
  )
}