import { EntranceExamPage } from "templates";

export const EntranceExam = () => {

  document.title = "EntranceExam | ADMIN SSJU";

  return (
    <>
      <EntranceExamPage/>
    </>
  );
};

export default EntranceExam;
