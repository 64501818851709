import { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex } from "atoms";
import { ResultForm } from "molecules"; 
import { PageLayout } from "organisms";
import { getResults, updateResult } from "redux/actions";
import { MdModeEdit } from "react-icons/md";

export const ResultPage = () => {

  const [ query, setQuery ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ type, setType ] = useState("create");
  const [ data, setData ] = useState(null);
  const [ fold, setFold ] = useState(true);  

  const dispatch = useDispatch();
  const { result } = useSelector(state => state.app);

  useEffect(()=>{
    dispatch(getResults(page, query))
  }, [query, page, dispatch]);

  useEffect(()=>{
    if(query) setPage(1);
  }, [query]);

  const columns = [
    {
      field: "action",
      headerName: "Action",
      minWidth: 80,      
      align: "center",
      renderCell: (params) => {
        return (   
          <Flex style={{ gap: "1rem"}}>
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color={params.row.hide ? "danger.500" : "success.500"}
              onClick={() => {                
                dispatch(updateResult({
                  id: params.row.id,
                  hide: !params.row.hide
                }))
              }}
            >
              <AiFillEye />            
            </Flex>  
            <Flex
              fontSize="2rem"            
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
              color={"warning.500"}
              onClick={() => {                
                setType("update");
                setData(result?.results?.find(i => i.id === params.row.id));
                setFold(false);
              }}
            >
              <MdModeEdit />            
            </Flex>  
          </Flex>           
        );
      }, 
      headerAlign: "center",
      disableColumnMenu: true, 
      sortable: false,
    },       
    { field: "id", headerName: "ID", minWidth: 100, flex: 1 },    
    { field: "title", headerName: "Title", minWidth: 200, flex: 1 },    
    { field: "courses", headerName: "Courses", minWidth: 150, flex: 0.5},
  ]

  const rows = [];
  result?.results?.forEach(r => {    
    rows.push({
      id: r.id,
      title: r.title,      
      hide: r.hide,
      courses: r?.resultCourses?.length,
    });
  });

  return (
    <PageLayout
      title="Manage Results"
      query={query}
      setQuery={setQuery}
      rows={rows}
      columns={columns} 
      pagination={true}
      resultPerPage={result?.resultPerPage}
      totalCount={result?.totalCount}
      page={page}
      setPage={setPage}
    >                    
      <Box my="1rem">   
        <ResultForm
          type={type}
          data={data}
          setType={setType}
          setData={setData}
          fold={fold}
          setFold={setFold}
        />
      </Box>    
    </PageLayout>
  )
}