import { SyllabusPage } from "templates";

export const NEPSyllabus = () => {

  document.title = "NEP Syllabus | ADMIN SSJU";

  return (
    <>
      <SyllabusPage
        mode="nep"
      />
    </>
  );
};

export default NEPSyllabus;
