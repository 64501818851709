import { useState } from "react";
import { FieldArray, Formik, Form } from "formik";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Image, Input, InputSelect, Loader } from "atoms";
import { Fold } from "molecules";
import { createNews } from "redux/actions";
import { upload } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  description: Yup.string()
    .required("Description Required"),  
});

const tags = [
  { value: "news", label: "News" },
  { value: "announcements", label: "Announcements" },
  { value: "media", label: "Media" },
  { value: "tender", label: "Tender" },
  { value: "press release", label: "Press Release" },
  { value: "students", label: "Students" },
  { value: "faculty", label: "Faculty" },
]

const newsType = [
  { value: "1", label: "Recent News" },
  { value: "2", label: "Announcements" },
  { value: "3", label: "Tenders" },
]

export const NewsForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const [ image, setImage ] = useState(null);
  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){      
      if(!image) toast.error("Image Required");
      setLocalLoading(true);
      let url = await upload(image);
      const form = {
        title: values.title,
        desc: values.description,
        image: url,
      }
      let src = [];
      for(let i = 0; i < values.newsSrc?.length; i++){
        if(values.newsSrc[i].type === "file"){
          let url = await upload(values.newsSrc[i].file);
          src.push({
            title: values.newsSrc[i].title,
            href: url,
          });
        }else{
          src.push({
            title: values.newsSrc[i].title,
            href: values.newsSrc[i].href,
          });
        }
      }      
      form.newsSrc = src;
      if(values.type) form.type = parseInt(values.type);
      if(values.tag) form.tag = values.tag;
      if(values.showPopup) form.showPopup = values.showPopup;
      setLocalLoading(false);
      dispatch(createNews(form));
    }    
    setImage(null);
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>   
      {localLoading && <Loader/>}   
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >
        <Fold
          title={type === "create" ? "Create News" : "Update News"}   
          fold={fold}
          setFold={setFold}
        />                                  
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            description: data?.description || "Check image for more info",
            tag: data?.tag || "",
            showPopup: data?.showPopup || false,
            newsSrc: data?.newsSrc || [],
            type: data?.type || "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  flexDirection={{xs: "column-reverse", md: "row"}}                  
                  style={{gap: "1rem"}}
                >                  
                <Box flexGrow="1">
                    <Input   
                      name="title"
                      type="text"
                      id="title"
                      touched={touched.title}
                      formik={true}
                      errors={errors.title}      
                      label="Title"
                      value={values.title}                         
                      mb="1.5rem"
                    />
                    <Input   
                      name="description"
                      type="text"
                      id="description"
                      touched={touched.description}
                      formik={true}
                      errors={errors.description}      
                      label="Description"
                      value={values.description}
                      as="textarea"                      
                      rows={5}
                      mb="1.5rem"
                    /> 
                    <Flex
                      flexDirection={{xs: "column", md: "row"}}                      
                      style={{gap: "1rem"}}
                    >
                      <InputSelect   
                        value={values.tag}
                        setValue={setFieldValue}
                        options={tags}
                        label="Tag"
                        formik={true}
                        fieldName="tag"
                        touched={touched.tag}
                        errors={errors.tag}  
                        name={"tag"}
                        id={"tag"}
                        placeholder="Select Tag"                        
                      />
                      <InputSelect
                        value={values.type}
                        setValue={setFieldValue}
                        options={newsType}
                        label="Type"
                        formik={true}
                        fieldName="type"
                        touched={touched.type}
                        errors={errors.type}
                        name={"type"}
                        id={"type"}
                        placeholder="Select Type"                                                
                      />
                      <Button                      
                        type="button"
                        variant="warning"
                        p="0.6rem 1rem"
                        width="fit-content"
                        borderRadius="0.5rem"
                        onClick={() => setFieldValue(
                          "newsSrc",
                          [...values.newsSrc, { title: "", href: "", type: "", file: null }]
                        )}
                      >
                        Add Source
                      </Button>
                      <FormControlLabel
                        control={
                          <Switch                          
                            checked={values.showPopup}
                            onChange={(e) => {
                              setFieldValue("showPopup", e.target.checked);
                            }}
                            name="showPopup"
                            color="secondary"
                          />
                        }
                        label="Show Popup?"
                      />
                    </Flex>                                        
                  </Box>                                   
                  <Flex
                    alignItems="center"
                    flexDirection="column"
                    style={{gap: "1rem"}}
                  >                        
                    {image && <Box
                      width="10rem"
                      height="10rem"                      
                    >
                      <Image
                        src={URL.createObjectURL(image)}
                        width="100%"
                        height="100%"                        
                        objectFit="cover"
                        borderRadius="0.5rem"
                        alt="image"
                      />
                    </Box>}
                    <Button
                      as="label"
                      htmlFor="image"
                      variant="info"                      
                      type="button"
                      p="0.5rem 1rem"                      
                      width="fit-content"
                      borderRadius="0.5rem"                      
                    >
                      Pick Image
                    </Button>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"                      
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      style={{
                        display: "none",
                      }}
                    />                    
                  </Flex>
                </Flex>               
                <FieldArray
                  name="newsSrc"
                  render={(arrayHelpers) => (
                    <Flex
                      mt="1.5rem"
                      flexDirection="column"
                      style={{gap: "1rem"}}
                    >
                      {values.newsSrc?.map((item, index) => (
                        <Flex 
                          key={index}
                          flexDirection={{xs: "column", md: "row"}}
                          alignItems={{md: "center"}}                          
                          style={{gap: "1rem"}}
                        >                              
                          <Input
                            name={`newsSrc.${index}.title`}
                            type="text"
                            id={`newsSrc.${index}.title`}
                            touched={touched.newsSrc}
                            formik={true}
                            errors={errors.newsSrc}      
                            label={`Source Title ${index + 1}`}
                            value={values.newsSrc[index].title}                                                    
                          />                          
                          <InputSelect
                            value={values.newsSrc[index].type}
                            setValue={setFieldValue}
                            options={[
                              { value: "link", label: "Link" },
                              { value: "file", label: "File" },                            
                            ]}
                            label="Source Type"                                             
                            formik={true}
                            fieldName={`newsSrc.${index}.type`}
                            touched={touched.newsSrc}
                            errors={errors.newsSrc}  
                            name={`newsSrc.${index}.type`}
                            id={`newsSrc.${index}.type`}
                            placeholder={`Select Source Type ${index + 1}`}                                                                                                                                        
                          />                          
                          {values.newsSrc[index].type === "link" && <Input
                            name={`newsSrc.${index}.href`}
                            type="text"
                            id={`newsSrc.${index}.href`}
                            touched={touched.newsSrc}
                            formik={true}
                            errors={errors.newsSrc}
                            label={`Source URL ${index + 1}`}
                            value={values.newsSrc[index].href}                     
                          />} 
                          {values.newsSrc[index].type === "file" && <Box>                          
                            <input
                              type="file"
                              name={`newsSrc.${index}.file`}
                              id={`newsSrc.${index}.file`}
                              onChange={(e) => {if(e.target.files[0]){
                                setFieldValue(`newsSrc.${index}.file`, e.target.files[0])
                              }}}                              
                            />
                          </Box>}                                                                          
                          <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            my="1rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>                         
                        </Flex>
                      ))}
                    </Flex>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}