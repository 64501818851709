import { userConstants } from "redux/constants"

const initState = {
  user: null,
  isAuthenticated: false,
  userLoading: true,
  loading: false,
  error: null,
  isUpdated: false,
  message: null,
  isResetPassword: false, 
  isForgotPassword: false, 
}

export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case userConstants.USER_LOGIN_REQUEST:  
    case userConstants.FORGOT_PASSWORD_REQUEST:        
      return{
        ...state,
        loading: true,
        isAuthenticated: false
      }
    
    case userConstants.UPDATE_PROFILE_REQUEST:
      return{
        ...state,
        loading: true,        
      }

    case userConstants.LOAD_USER_REQUEST:
      return{
        ...state,
        userLoading: true,
        isAuthenticated: false
      }

    case userConstants.USER_LOGIN_SUCCESS:
    case userConstants.LOAD_USER_SUCCESS:
      return{
        ...state,
        user: action.payload,
        isAuthenticated: true,        
        userLoading: false,
        loading: false,
      }

    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return{
        ...state,
        loading: false,
        isForgotPassword: action.payload.success,
        message: action.payload.message
      }

    case userConstants.LOGOUT_SUCCESS:
      return{
        ...state,
        user: {},
        isAuthenticated: false,
        userLoading: false,
        loading: false,
        message: "Logged Out Successfully"
      }    

    case userConstants.UPDATE_PROFILE_SUCCESS:
      return{
        ...state,
        loading: false,
        message: "Profile Updated Successfully",
      }
    
    case userConstants.USER_LOGIN_FAILURE:    
      return{
        ...state,
        loading: false,
        userLoading: false,
        isAuthenticated: false,
        user: {},
        error: action.payload
      }

    case userConstants.LOAD_USER_FAILURE:
      return{
        userLoading: false,
        isAuthenticated: false,
        user: {},           
      }
    
    case userConstants.FORGOT_PASSWORD_FAILURE:       
    case userConstants.UPDATE_PROFILE_FAILURE:
      return{
        ...state,
        loading: false,
        error: action.payload
      }

    case userConstants.LOGOUT_FAILURE:
      return{
        ...state,
        error: action.payload,
        userLoading: false,
      }

    case userConstants.RESET_USER:
      return{
        ...state,
        error: null,
        message: null,
        isUpdated: false,
        isResetPassword: false,
        isForgotPassword: false,        
      } 
      
    case userConstants.RESET:
      return {
        ...initState,
        userLoading: false,
      }
    
    default: return state
  }
}