import { CBDUsersPage } from "templates";

export const CBDUsers = () => {

  document.title = "CBD Users | ADMIN SSJU";

  return (
    <>
      <CBDUsersPage/>
    </>
  );
};

export default CBDUsers;
