import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,    
  event: {},
  examSchedule: {},  
  gallery: {},
  impLinks: null,
  news: {},
  result: {},
  nepSyllabus: {},
  entranceSyllabus: {},
  recruitment: {},
  sports: {},
  entranceExam: {},
  examForm: {},
  campuses: null,
  cBDUsers: null,
}

export const appReducer = (state = initState, action) => {
  let temp = null;  
  switch(action.type){
    case appConstants.GET_EVENT_REQUEST:
    case appConstants.CREATE_EVENT_REQUEST:
    case appConstants.UPDATE_EVENT_REQUEST:
    case appConstants.DELETE_EVENT_REQUEST:
    case appConstants.GET_EXAM_SCHEDULE_REQUEST:
    case appConstants.CREATE_EXAM_SCHEDULE_REQUEST:
    case appConstants.UPDATE_EXAM_SCHEDULE_REQUEST:    
    case appConstants.GET_GALLERY_REQUEST:
    case appConstants.CREATE_GALLERY_REQUEST:
    case appConstants.UPDATE_GALLERY_REQUEST:
    case appConstants.GET_IMP_LINKS_REQUEST:
    case appConstants.CREATE_IMP_LINKS_REQUEST:
    case appConstants.CREATE_IMP_URL_REQUEST:
    case appConstants.UPDATE_IMP_URL_REQUEST:
    case appConstants.HIDE_IMP_URL_REQUEST:
    case appConstants.GET_NEWS_REQUEST:
    case appConstants.CREATE_NEWS_REQUEST:
    case appConstants.UPDATE_NEWS_REQUEST:
    case appConstants.DELETE_NEWS_REQUEST:
    case appConstants.GET_RESULT_REQUEST:
    case appConstants.CREATE_RESULT_REQUEST:
    case appConstants.CREATE_RESULT_COURSES_REQUEST:
    case appConstants.UPDATE_RESULT_REQUEST:
    case appConstants.UPDATE_RESULT_COURSES_REQUEST:    
    case appConstants.GET_NEP_SYLLABUS_REQUEST:
    case appConstants.CREATE_NEP_SYLLABUS_REQUEST:
    case appConstants.UPDATE_NEP_SYLLABUS_REQUEST:
    case appConstants.GET_ENTRANCE_SYLLABUS_REQUEST:
    case appConstants.UPDATE_ENTRANCE_SYLLABUS_REQUEST:
    case appConstants.GET_RECRUITMENT_REQUEST:
    case appConstants.CREATE_RECRUITMENT_REQUEST:
    case appConstants.UPDATE_RECRUITMENT_REQUEST:
    case appConstants.DELETE_RECRUITMENT_REQUEST:
    case appConstants.GET_SPORTS_REQUEST:      
    case appConstants.CREATE_SPORTS_REQUEST:
    case appConstants.UPDATE_SPORTS_REQUEST:
    case appConstants.DELETE_SPORTS_REQUEST:
    case appConstants.GET_ENTRANCE_EXAM_REQUEST:
    case appConstants.CREATE_ENTRANCE_EXAM_REQUEST:
    case appConstants.UPDATE_ENTRANCE_EXAM_REQUEST:
    case appConstants.ADD_DOC_TO_ENTRANCE_EXAM_REQUEST:
    case appConstants.GET_EXAM_FORM_REQUEST:
    case appConstants.CREATE_EXAM_FORM_REQUEST:
    case appConstants.UPDATE_EXAM_FORM_REQUEST:
    case appConstants.UPDATE_EXAM_FORM_DETAIL_REQUEST:
    case appConstants.CREATE_MEDIA_REQUEST:   
    case appConstants.GET_CAMPUS_REQUEST: 
    case appConstants.CREATE_CAMPUS_REQUEST:  
    case appConstants.CREATE_CAMPUS_COLLEGE_REQUEST:
    case appConstants.UPDATE_CAMPUS_COLLEGE_REQUEST:
    case appConstants.GET_CBD_USERS_REQUEST:
    case appConstants.CREATE_CBD_USER_REQUEST:
    case appConstants.UPDATE_CBD_USER_REQUEST:
    case appConstants.GET_SGR_USERS_REQUEST:
    case appConstants.CREATE_SGR_USER_REQUEST:
    case appConstants.UPDATE_SGR_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case appConstants.GET_EVENT_SUCCESS:
      return{
        ...state,
        event: action.payload,
        loading: false,
      }

    case appConstants.CREATE_EVENT_SUCCESS: 
      temp = state.event
      if(temp?.events?.length > 0){
        temp.events = [action.payload.data, ...temp.events]
      }else{
        temp["events"] = [action.payload.data]
      }
      return{
        ...state,
        event: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_EVENT_SUCCESS:
      temp = state.event
      for(let i = 0; i < temp?.events?.length; i++){
        if(temp?.events[i].id === action.payload.data.id){
          temp.events[i] = action.payload.data
          break;
        }
      }
      return{
        ...state,
        event: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_EVENT_SUCCESS:
      temp = state.event
      for(let i = 0; i < temp?.events?.length; i++){
        if(temp?.events[i].id === action.payload.data.id){
          temp?.events.splice(i, 1)
          break;
        }
      }
      return{
        ...state,
        event: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_EXAM_SCHEDULE_SUCCESS:
      return{
        ...state,
        examSchedule: action.payload,
        loading: false,
      }

    case appConstants.CREATE_EXAM_SCHEDULE_SUCCESS:
      temp = state.examSchedule
      if(temp?.schedules?.length > 0){
        if(action.payload.type === "update"){
          for(let i = 0; i < temp?.schedules?.length; i++){
            if(temp?.schedules[i].id === action.payload.data.id){
              temp.schedules[i] = action.payload.data
              break;
            }
          }
        }else temp.schedules = [action.payload.data, ...temp.schedules]
      }else{
        temp["schedules"] = [action.payload.data]
      }
      return{
        ...state,
        examSchedule: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_EXAM_SCHEDULE_SUCCESS:
      temp = state.examSchedule
      for(let i = 0; i < temp?.schedules?.length; i++){
        if(temp?.schedules[i].id === action.payload.data.id){
          temp.schedules[i] = action.payload.data
          break;
        }
      }
      return{
        ...state,
        examSchedule: temp,
        message: action.payload.message,
        loading: false,
      } 

    case appConstants.GET_GALLERY_SUCCESS:
      return{
        ...state,
        gallery: action.payload,
        loading: false,
      }

    case appConstants.CREATE_GALLERY_SUCCESS:
      temp = state.gallery
      if(temp?.albums?.length > 0){
        temp.albums = [action.payload.data, ...temp.albums]
      }else{
        temp["albums"] = [action.payload.data]
      }
      return{
        ...state,
        gallery: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_GALLERY_SUCCESS:
      temp = state.gallery
      for(let i = 0; i < temp?.albums?.length; i++){
        if(temp?.albums[i].id === action.payload.data.id){
          temp.albums[i] = action.payload.data
          break;
        }
      }      
      return{
        ...state,
        gallery: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_IMP_LINKS_SUCCESS:
      return{
        ...state,
        impLinks: action.payload,
        loading: false,
      }

    case appConstants.CREATE_IMP_LINKS_SUCCESS:      
      return{
        ...state,
        impLinks: [action.payload.data, ...state?.impLinks],
        message: action.payload.message,
        loading: false,
      }

    case appConstants.CREATE_IMP_URL_SUCCESS:
      temp = state.impLinks
      for(let i = 0; i < temp?.length; i++){
        if(temp[i].id === action.payload.data.id){
          temp[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        impLinks: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_IMP_URL_SUCCESS:
      temp = state.impLinks
      for(let i = 0; i < temp?.length; i++){
        if(temp[i].id === action.payload.data.importantLinkId){
          for(let j = 0; j < temp[i].importantLinkUrl.length; j++){
            if(temp[i].importantLinkUrl[j].id === action.payload.data.id){
              temp[i].importantLinkUrl[j] = action.payload.data
              break
            }
          }
          break
        }
      }      

      return{
        ...state,
        impLinks: temp,
        message: action.payload.message,
        loading: false,       
      }

    case appConstants.HIDE_IMP_URL_SUCCESS:
      temp = state.impLinks
      for(let i = 0; i < temp?.length; i++){
        if(temp[i].id === action.payload.data.importantLinkId){
          for(let j = 0; j < temp[i].importantLinkUrl.length; j++){
            if(temp[i].importantLinkUrl[j].id === action.payload.data.id){
              temp[i].importantLinkUrl[j] = action.payload.data
              break
            }
          }
          break
        }
      }
      return{
        ...state,
        impLinks: temp,
        message: action.payload.message,
        loading: false,       
      }

    case appConstants.GET_NEWS_SUCCESS:
      return{
        ...state,
        news: action.payload,
        loading: false,
      }

    case appConstants.CREATE_NEWS_SUCCESS:
      temp = state.news
      if(temp?.news?.length > 0){
        temp.news = [action.payload.data, ...temp.news]
      }else{
        temp["news"] = [action.payload.data]
      }
      return{
        ...state,
        news: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_NEWS_SUCCESS:
      temp = state.news
      for(let i = 0; i < temp?.news?.length; i++){
        if(temp?.news[i].id === action.payload.data.id){
          temp.news[i] = action.payload.data
          break;
        }
      }
      return{
        ...state,
        news: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_NEWS_SUCCESS:
      temp = state.news
      for(let i = 0; i < temp?.news?.length; i++){
        if(temp?.news[i].id === action.payload.data.id){
          temp.news.splice(i, 1)
          break;
        }
      }
      return{
        ...state,
        news: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_RESULT_SUCCESS:
      return{
        ...state,
        result: action.payload,
        loading: false,        
      }

    case appConstants.CREATE_RESULT_SUCCESS:
      temp = state.result
      if(temp?.results?.length > 0){
        temp.results = [action.payload.data, ...temp.results]
      }else{
        temp["results"] = [action.payload.data]
      }
      return{
        ...state,
        result: temp,
        message: action.payload.message,
        loading: false,
      }
      
    case appConstants.UPDATE_RESULT_SUCCESS:
      temp = state.result
      for(let i = 0; i < temp?.results?.length; i++){
        if(temp.results[i].id === action.payload.data.id){
          temp.results[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        result: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.CREATE_RESULT_COURSES_SUCCESS:
      temp = state.result
      for(let i = 0; i < temp?.results?.length; i++){
        if(temp.results[i].id === action.payload.data.id){
          temp.results[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        result: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_RESULT_COURSES_SUCCESS:
      temp = state.result
      for(let i = 0; i < temp?.results?.length; i++){
        if(temp.results[i].id === action.payload.data.resultId){
          for(let j = 0; j < temp.results[i].resultCourses.length; j++){
            if(temp.results[i].resultCourses[j].id === action.payload.data.id){
              temp.results[i].resultCourses[j] = action.payload.data
              break
            }
          }
          break
        }
      }
      return{
        ...state,
        result: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_NEP_SYLLABUS_SUCCESS:
      return{
        ...state,
        nepSyllabus: action.payload,
        loading: false,
      }

    case appConstants.CREATE_NEP_SYLLABUS_SUCCESS:
      temp = state.nepSyllabus
      if(temp?.syllabus?.length > 0){
        temp.syllabus = [action.payload.data, ...temp.syllabus]
      }else{
        temp["syllabus"] = [action.payload.data]
      }
      return{
        ...state,
        nepSyllabus: temp,
        message: action.payload.message,
        loading: false,
      }
    
    case appConstants.UPDATE_NEP_SYLLABUS_SUCCESS:
      temp = state.nepSyllabus
      for(let i = 0; i < temp?.syllabus?.length; i++){
        if(temp.syllabus[i].id === action.payload.data.id){
          temp.syllabus[i] = action.payload.data
          break
        }
      }
      return{
        ...state,        
        nepSyllabus: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_ENTRANCE_SYLLABUS_SUCCESS:
      return{
        ...state,
        entranceSyllabus: action.payload,
        loading: false,
      }

    case appConstants.CREATE_ENTRANCE_SYLLABUS_SUCCESS:
      temp = state.entranceSyllabus
      if(temp?.syllabus?.length > 0){
        temp.syllabus = [action.payload.data, ...temp.syllabus]
      }else{
        temp["syllabus"] = [action.payload.data]
      }
      return{
        ...state,
        entranceSyllabus: temp,        
        message: action.payload.message,
        loading: false,
      }
    
    case appConstants.UPDATE_ENTRANCE_SYLLABUS_SUCCESS:
      temp = state.entranceSyllabus
      for(let i = 0; i < temp?.syllabus?.length; i++){
        if(temp.syllabus[i].id === action.payload.data.id){
          temp.syllabus[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        entranceSyllabus: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_RECRUITMENT_SUCCESS:
      return{
        ...state,
        recruitment: action.payload,
        loading: false,
      }

    case appConstants.CREATE_RECRUITMENT_SUCCESS:
      temp = state.recruitment
      if(temp?.recruitments?.length > 0){
        temp.recruitments = [action.payload.data, ...temp.recruitments]
      }else{
        temp["recruitments"] = [action.payload.data]
      }
      return{
        ...state,
        recruitment: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_RECRUITMENT_SUCCESS:
      temp = state.recruitment
      for(let i = 0; i < temp?.recruitments?.length; i++){
        if(temp.recruitments[i].id === action.payload.data.id){
          temp.recruitments[i] = action.payload.data
          break
        }
      }      
      return{
        ...state,
        recruitment: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_RECRUITMENT_SUCCESS:
      temp = state.recruitment
      for(let i = 0; i < temp?.recruitments?.length; i++){
        if(temp.recruitments[i].id === action.payload.data.id){
          temp.recruitments.splice(i, 1)
          break
        }
      }
      return{
        ...state,
        recruitment: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_SPORTS_SUCCESS:      
      return{
        ...state,
        sports: action.payload,
        loading: false,        
      }

    case appConstants.CREATE_SPORTS_SUCCESS:
      temp = state.sports
      if(temp?.sports?.length > 0){
        temp.sports = [action.payload.data, ...temp.sports]
      }else{
        temp["sports"] = [action.payload.data]
      }
      return{
        ...state,
        sports: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_SPORTS_SUCCESS:
      temp = state.sports
      for(let i = 0; i < temp?.sports?.length; i++){
        if(temp.sports[i].id === action.payload.data.id){
          temp.sports[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        sports: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.DELETE_SPORTS_SUCCESS:
      temp = state.sports
      for(let i = 0; i < temp?.sports?.length; i++){
        if(temp.sports[i].id === action.payload.data.id){
          temp.sports.splice(i, 1)
          break
        }
      }
      return{
        ...state,
        sports: temp,
        message: action.payload.message,
        loading: false,
      }
      
    case appConstants.GET_ENTRANCE_EXAM_SUCCESS:
      return{
        ...state,
        entranceExam: action.payload,
        loading: false,
      }

    case appConstants.CREATE_ENTRANCE_EXAM_SUCCESS:
      temp = state.entranceExam
      if(temp?.exams?.length > 0){
        temp.exams = [action.payload.data, ...temp.exams]
      }else{
        temp["exams"] = [action.payload.data]
      }
      return{
        ...state,
        entranceExam: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_ENTRANCE_EXAM_SUCCESS:
      temp = state.entranceExam
      for(let i = 0; i < temp?.exams?.length; i++){
        if(temp.exams[i].id === action.payload.data.id){
          temp.exams[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        entranceExam: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.ADD_DOC_TO_ENTRANCE_EXAM_SUCCESS:
      temp = state.entranceExam
      for(let i = 0; i < temp?.exams?.length; i++){
        if(temp.exams[i].id === action.payload.data.id){
          temp.exams[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        entranceExam: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_EXAM_FORM_SUCCESS:
      return{
        ...state,
        examForm: action.payload,
        loading: false,
      }

    case appConstants.CREATE_EXAM_FORM_SUCCESS:
      temp = state.examForm
      if(temp?.forms?.length > 0){
        temp.forms = [action.payload.data, ...temp.forms]
      }else{
        temp["forms"] = [action.payload.data]
      }
      return{
        ...state,
        examForm: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_EXAM_FORM_SUCCESS:
      temp = state.examForm
      for(let i = 0; i < temp?.forms?.length; i++){
        if(temp.forms[i].id === action.payload.data.id){
          temp.forms[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        examForm: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_EXAM_FORM_DETAIL_SUCCESS:
      temp = state.examForm
      for(let i = 0; i < temp?.forms?.length; i++){
        if(temp.forms[i].id === action.payload.data.id){
          temp.forms[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        examForm: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.CREATE_MEDIA_SUCCESS:      
      return{
        ...state,        
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_CAMPUS_SUCCESS:
      return{
        ...state,
        campuses: action.payload,
        loading: false,
      }

    case appConstants.CREATE_CAMPUS_SUCCESS:
      temp = state.campuses
      if(temp?.length > 0){
        temp = [action.payload.data, ...temp]
      }else{
        temp = [action.payload.data]
      }
      return{
        ...state,
        campuses: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.CREATE_CAMPUS_COLLEGE_SUCCESS:  
    case appConstants.UPDATE_CAMPUS_COLLEGE_SUCCESS:    
      return{
        ...state,        
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_CBD_USERS_SUCCESS:
      return{
        ...state,
        cBDUsers: action.payload,
        loading: false,
      }

    case appConstants.CREATE_CBD_USER_SUCCESS:
      temp = state.cBDUsers
      if(temp?.length > 0){
        temp = [action.payload.data, ...temp]
      }else{
        temp = [action.payload.data]
      }
      return{
        ...state,
        cBDUsers: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_CBD_USER_SUCCESS:
      temp = state.cBDUsers
      for(let i = 0; i < temp?.length; i++){
        if(temp[i].id === action.payload.data.id){
          temp[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        cBDUsers: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_SGR_USERS_SUCCESS:
      return{
        ...state,
        sGRUsers: action.payload,
        loading: false,
      }

    case appConstants.CREATE_SGR_USER_SUCCESS:
      temp = state.sGRUsers
      if(temp?.length > 0){
        temp = [action.payload.data, ...temp]
      }else{
        temp = [action.payload.data]
      }
      return{
        ...state,
        sGRUsers: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.UPDATE_SGR_USER_SUCCESS:
      temp = state.sGRUsers
      for(let i = 0; i < temp?.length; i++){
        if(temp[i].id === action.payload.data.id){
          temp[i] = action.payload.data
          break
        }
      }
      return{
        ...state,
        sGRUsers: temp,
        message: action.payload.message,
        loading: false,
      }

    case appConstants.GET_EVENT_FAILURE:
    case appConstants.CREATE_EVENT_FAILURE:
    case appConstants.UPDATE_EVENT_FAILURE:
    case appConstants.DELETE_EVENT_FAILURE:
    case appConstants.GET_EXAM_SCHEDULE_FAILURE:
    case appConstants.CREATE_EXAM_SCHEDULE_FAILURE:
    case appConstants.UPDATE_EXAM_SCHEDULE_FAILURE:      
    case appConstants.GET_GALLERY_FAILURE:
    case appConstants.CREATE_GALLERY_FAILURE:
    case appConstants.UPDATE_GALLERY_FAILURE:
    case appConstants.GET_IMP_LINKS_FAILURE:
    case appConstants.CREATE_IMP_LINKS_FAILURE:
    case appConstants.CREATE_IMP_URL_FAILURE:
    case appConstants.UPDATE_IMP_URL_FAILURE:
    case appConstants.HIDE_IMP_URL_FAILURE:
    case appConstants.GET_NEWS_FAILURE:
    case appConstants.CREATE_NEWS_FAILURE:
    case appConstants.UPDATE_NEWS_FAILURE:
    case appConstants.DELETE_NEWS_FAILURE:
    case appConstants.GET_RESULT_FAILURE:
    case appConstants.CREATE_RESULT_FAILURE:
    case appConstants.CREATE_RESULT_COURSES_FAILURE:
    case appConstants.UPDATE_RESULT_FAILURE:
    case appConstants.UPDATE_RESULT_COURSES_FAILURE:    
    case appConstants.GET_NEP_SYLLABUS_FAILURE:
    case appConstants.CREATE_NEP_SYLLABUS_FAILURE:
    case appConstants.UPDATE_NEP_SYLLABUS_FAILURE:
    case appConstants.GET_ENTRANCE_SYLLABUS_FAILURE:
    case appConstants.UPDATE_ENTRANCE_SYLLABUS_FAILURE:
    case appConstants.GET_RECRUITMENT_FAILURE:
    case appConstants.CREATE_RECRUITMENT_FAILURE:
    case appConstants.UPDATE_RECRUITMENT_FAILURE:
    case appConstants.DELETE_RECRUITMENT_FAILURE:
    case appConstants.GET_SPORTS_FAILURE:      
    case appConstants.CREATE_SPORTS_FAILURE:
    case appConstants.UPDATE_SPORTS_FAILURE:
    case appConstants.DELETE_SPORTS_FAILURE:
    case appConstants.GET_ENTRANCE_EXAM_FAILURE:
    case appConstants.CREATE_ENTRANCE_EXAM_FAILURE:
    case appConstants.UPDATE_ENTRANCE_EXAM_FAILURE:
    case appConstants.ADD_DOC_TO_ENTRANCE_EXAM_FAILURE:
    case appConstants.GET_EXAM_FORM_FAILURE:
    case appConstants.CREATE_EXAM_FORM_FAILURE:
    case appConstants.UPDATE_EXAM_FORM_FAILURE:
    case appConstants.UPDATE_EXAM_FORM_DETAIL_FAILURE:
    case appConstants.CREATE_MEDIA_FAILURE: 
    case appConstants.GET_CAMPUS_FAILURE: 
    case appConstants.CREATE_CAMPUS_FAILURE:    
    case appConstants.CREATE_CAMPUS_COLLEGE_FAILURE:
    case appConstants.UPDATE_CAMPUS_COLLEGE_FAILURE:
    case appConstants.GET_CBD_USERS_FAILURE:
    case appConstants.CREATE_CBD_USER_FAILURE:
    case appConstants.UPDATE_CBD_USER_FAILURE:
    case appConstants.GET_SGR_USERS_FAILURE:
    case appConstants.CREATE_SGR_USER_FAILURE:
    case appConstants.UPDATE_SGR_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case appConstants.RESET_APP:
      return {
        ...state,
        loading: false,
        error: null,
        message: null,
      }

    case appConstants.RESET:
      return initState

    default: return state;
  }
}