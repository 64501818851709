import { FieldArray, Formik, Form } from "formik";
import { AiFillEye } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Box, Button, Flex, Input } from "atoms";
import { Fold } from "molecules";
import { createImpLink, createImpUrl, updateImpUrl, hideImpUrl } from "redux/actions";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  index: Yup.number()
    .required("Index Required"),
});

export const ImpLinkForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      const form = {
        title: values.title,
        index: values.index,
      }
      dispatch(createImpLink(form));
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >
        <Fold
          title={type === "create" ? "Create Important Link" : "Update Important Link"}
          fold={fold}
          setFold={setFold}
        />                         
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            index: data?.index || "",
            image: data?.image || "",
            importantLinkUrl: data?.importantLinkUrl || [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                >                   
                  <Input   
                    name="title"
                    type="text"
                    id="title"
                    touched={touched.title}
                    formik={true}
                    errors={errors.title}      
                    label="Title"
                    value={values.title}                                               
                  />                                  
                </Flex>
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                  mt="1.5rem"
                >                  
                  <Input   
                    name="image"
                    type="text"
                    id="image"
                    touched={touched.image}
                    formik={true}
                    errors={errors.image}      
                    label="Image"
                    value={values.image}                                               
                  />                   
                  <Input   
                    name="index"
                    type="text"
                    id="index"
                    touched={touched.index}
                    formik={true}
                    errors={errors.index}      
                    label="Index"
                    value={values.index}                                               
                  />                   
                {type==="update" && <Button
                  type="button"
                  variant="secondary"
                  width="fit-content"
                  px="2rem"
                  py="1rem"
                  borderRadius="0.5rem" 
                  onClick={() => {
                    setFieldValue("importantLinkUrl", [...values?.importantLinkUrl, {title: "", href: "", new: true, ext: false, hide: false }])                      
                  }}                   
                >
                  Add URL
                </Button>}
                </Flex>
                <FieldArray
                  name="importantLinkUrl"
                  render={(arrayHelpers) => (
                    <Flex 
                      mt="1.5rem"
                      flexDirection="column"
                      style={{gap: "1.5rem"}}
                    >
                      {values.importantLinkUrl?.map((item, index) => (
                        <Flex 
                          key={index}
                          flexDirection={{xs: "column", md: "row"}}
                          alignItems={{md: "center"}}                  
                          style={{gap: "1rem"}}
                        >    
                          {item.id && <Flex
                            fontSize="2rem"            
                            cursor="pointer"
                            alignItems="center"
                            justifyContent="center"
                            color={!item.hide ? "success.500" : "danger.500"}              
                            onClick={() => {                              
                              dispatch(hideImpUrl({
                                id: item.id,
                                hide: !item.hide
                              }))           
                              setFieldValue(`importantLinkUrl.${index}.hide`, !item.hide)                   
                            }}
                          >
                            <AiFillEye />            
                          </Flex>}                                                          
                          <Input
                            name={`importantLinkUrl.${index}.title`}
                            type="text"
                            id={`importantLinkUrl.${index}.title`}
                            touched={touched.importantLinkUrl}
                            formik={true}
                            errors={errors.importantLinkUrl}      
                            label={`URL Title ${index + 1}`}
                            value={item.title}
                          />                         
                          <Input
                            name={`importantLinkUrl.${index}.href`}
                            type="text"
                            id={`importantLinkUrl.${index}.href`}
                            touched={touched.importantLinkUrl}
                            formik={true}
                            errors={errors.importantLinkUrl}      
                            label={`URL ${index + 1}`}
                            value={item.href}                            
                          />                                                  
                          <FormControlLabel
                            control={
                              <Switch                          
                                checked={item.new}
                                onChange={(e) => {
                                  setFieldValue(`importantLinkUrl.${index}.new`, e.target.checked)
                                }}
                                name={`importantLinkUrl.${index}.new`}
                                color="primary"
                              />
                            }
                            label="New Link?"
                          />                                            
                          {type==="update" && <Button
                            type="button"
                            variant="success"
                            width="fit-content"
                            px="2rem"
                            py="1rem"                            
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"   
                            onClick={() => {
                              if(item.id){
                                dispatch(updateImpUrl({
                                  id: item.id,
                                  title: item.title,
                                  href: item.href,
                                  ext: item.ext,
                                  isNew: item.new,
                                }));                                
                              }else{
                                dispatch(createImpUrl({
                                  id: data.id,
                                  title: item.title,
                                  href: item.href,
                                  ext: item.ext,
                                  isNew: item.new,
                                })); 
                                setData(null);
                                setType("create");
                                resetForm();
                              }
                            }}                            
                          >                           
                            <BsCheckLg/>                           
                          </Button>}
                          {type==="update" && !item.id && <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>}                         
                        </Flex>
                      ))}
                    </Flex>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}