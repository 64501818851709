import { useState } from "react";
import { FieldArray, Formik, Form } from "formik";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Image, Input, InputSelect, Loader } from "atoms";
import { Fold } from "molecules";
import { createRecruitment } from "redux/actions";
import { upload } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  description: Yup.string()
    .required("Description Required"),  
});

export const RecruitmentForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const [ image, setImage ] = useState(null);
  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){      
      if(!image) toast.error("Image Required");
      setLocalLoading(true);
      let url = await upload(image);
      const form = {
        title: values.title,
        desc: values.description,
        image: url,
      }
      let src = [];
      for(let i = 0; i < values.recuritmentSrc?.length; i++){
        if(values.recuritmentSrc[i].type === "file"){
          let url = await upload(values.recuritmentSrc[i].file);
          src.push({
            title: values.recuritmentSrc[i].title,
            href: url,
          });
        }else{
          src.push({
            title: values.recuritmentSrc[i].title,
            href: values.recuritmentSrc[i].href,
          });
        }
      }      
      form.recuritmentSrc = src;      
      setLocalLoading(false);
      dispatch(createRecruitment(form));
    }    
    setImage(null);
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>   
      {localLoading && <Loader/>}   
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      > 
        <Fold
          title={type === "create" ? "Create Recruitment" : "Update Recruitment"}
          fold={fold}
          setFold={setFold}
        />                                 
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            description: data?.description || "Check image for more info",            
            recuritmentSrc: data?.recuritmentSrc || [],            
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Flex
                  flexDirection={{xs: "column", md: "row"}}                  
                  style={{gap: "1rem"}}
                >
                  <Flex                    
                    flexDirection={{xs: "column", md: "row"}}                    
                    justifyContent={{md: "space-between"}}
                    style={{gap: "1rem"}}
                    flex="1"
                  > 
                    <Box flexGrow="1">
                      <Input   
                        name="title"
                        type="text"
                        id="title"
                        touched={touched.title}
                        formik={true}
                        errors={errors.title}      
                        label="Title"
                        value={values.title} 
                        mb="1rem"                                              
                      />
                      <Input   
                        name="description"
                        type="text"
                        id="description"
                        touched={touched.description}
                        formik={true}
                        errors={errors.description}      
                        label="Description"
                        value={values.description}
                        as="textarea"                      
                        rows={5}
                      />  
                    </Box>                     
                  </Flex>
                  <Flex
                    alignItems="center"
                    flexDirection="column"
                    style={{gap: "1rem"}}
                  >                        
                    {image && <Box
                      width="10rem"
                      height="10rem"                     
                    >
                      <Image
                        src={URL.createObjectURL(image)}
                        width="100%"
                        height="100%"                        
                        objectFit="cover"
                        borderRadius="0.5rem"
                        alt="image"
                      />
                    </Box>}
                    <Button
                      as="label"
                      htmlFor="image"
                      variant="info"                      
                      type="button"
                      p="0.5rem 1rem"                      
                      width="fit-content"
                      borderRadius="0.5rem"                      
                    >
                      Pick Image
                    </Button>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"                      
                      onChange={(e) => {
                        setImage(e.target.files[0]);
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                    <Button                     
                      type="button"
                      variant="warning"
                      p="0.6rem 1rem"
                      width="fit-content"
                      borderRadius="0.5rem"
                      onClick={() => setFieldValue(
                        "recuritmentSrc",
                        [...values.recuritmentSrc, { title: "", href: "", type: "", file: null }]
                      )}
                    >
                      Add Source
                    </Button>
                  </Flex>
                </Flex>                
                <FieldArray
                  name="recuritmentSrc"
                  render={(arrayHelpers) => (
                    <Flex 
                      mt="1.5rem"
                      flexDirection="column"
                      style={{gap: "1rem"}}
                    >
                      {values.recuritmentSrc?.map((item, index) => (
                        <Flex 
                          key={index}
                          flexDirection={{xs: "column", md: "row"}}
                          alignItems={{md: "center"}}                  
                          style={{gap: "1rem"}}
                        >                              
                            <Input
                              name={`recuritmentSrc.${index}.title`}
                              type="text"
                              id={`recuritmentSrc.${index}.title`}
                              touched={touched.recuritmentSrc}
                              formik={true}
                              errors={errors.recuritmentSrc}      
                              label={`Source Title ${index + 1}`}
                              value={values.recuritmentSrc[index].title}                                                                                 
                            />                          
                            <InputSelect
                              value={values.recuritmentSrc[index].type}
                              setValue={setFieldValue}
                              options={[
                                { value: "link", label: "Link" },
                                { value: "file", label: "File" },                            
                              ]}
                              label="Source Type"                                             
                              formik={true}
                              fieldName={`recuritmentSrc.${index}.type`}
                              touched={touched.recuritmentSrc}
                              errors={errors.recuritmentSrc}  
                              name={`recuritmentSrc.${index}.type`}
                              id={`recuritmentSrc.${index}.type`}
                              placeholder={`Select Source Type ${index + 1}`}                                                                                                                                        
                            />                          
                          {values.recuritmentSrc[index].type === "link" && <Input
                            name={`recuritmentSrc.${index}.href`}
                            type="text"
                            id={`recuritmentSrc.${index}.href`}
                            touched={touched.recuritmentSrc}
                            formik={true}
                            errors={errors.recuritmentSrc}
                            label={`Source URL ${index + 1}`}
                            value={values.recuritmentSrc[index].href}
                          />} 
                          {values.recuritmentSrc[index].type === "file" && <Box>                          
                            <input
                              type="file"
                              name={`recuritmentSrc.${index}.file`}
                              id={`recuritmentSrc.${index}.file`}
                              onChange={(e) => {if(e.target.files[0]){
                                setFieldValue(`recuritmentSrc.${index}.file`, e.target.files[0])
                              }}}                              
                            />
                          </Box>}                                                                          
                          <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            my="1rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>                         
                        </Flex>
                      ))}
                    </Flex>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}