export const appConstants = {      
  GET_EVENT_REQUEST: "GET_EVENT_REQUEST",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILURE: "GET_EVENT_FAILURE",
  CREATE_EVENT_REQUEST: "CREATE_EVENT_REQUEST",
  CREATE_EVENT_SUCCESS: "CREATE_EVENT_SUCCESS",
  CREATE_EVENT_FAILURE: "CREATE_EVENT_FAILURE",
  UPDATE_EVENT_REQUEST: "UPDATE_EVENT_REQUEST",
  UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILURE: "UPDATE_EVENT_FAILURE",
  DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",
  GET_EXAM_SCHEDULE_REQUEST: "GET_EXAM_SCHEDULE_REQUEST",
  GET_EXAM_SCHEDULE_SUCCESS: "GET_EXAM_SCHEDULE_SUCCESS",
  GET_EXAM_SCHEDULE_FAILURE: "GET_EXAM_SCHEDULE_FAILURE",
  CREATE_EXAM_SCHEDULE_REQUEST: "CREATE_EXAM_SCHEDULE_REQUEST",
  CREATE_EXAM_SCHEDULE_SUCCESS: "CREATE_EXAM_SCHEDULE_SUCCESS",
  CREATE_EXAM_SCHEDULE_FAILURE: "CREATE_EXAM_SCHEDULE_FAILURE",
  UPDATE_EXAM_SCHEDULE_REQUEST: "UPDATE_EXAM_SCHEDULE_REQUEST",
  UPDATE_EXAM_SCHEDULE_SUCCESS: "UPDATE_EXAM_SCHEDULE_SUCCESS",
  UPDATE_EXAM_SCHEDULE_FAILURE: "UPDATE_EXAM_SCHEDULE_FAILURE",  
  GET_GALLERY_REQUEST: "GET_GALLERY_REQUEST",
  GET_GALLERY_SUCCESS: "GET_GALLERY_SUCCESS",
  GET_GALLERY_FAILURE: "GET_GALLERY_FAILURE",
  CREATE_GALLERY_REQUEST: "CREATE_GALLERY_REQUEST",
  CREATE_GALLERY_SUCCESS: "CREATE_GALLERY_SUCCESS",
  CREATE_GALLERY_FAILURE: "CREATE_GALLERY_FAILURE",
  UPDATE_GALLERY_REQUEST: "UPDATE_GALLERY_REQUEST",
  UPDATE_GALLERY_SUCCESS: "UPDATE_GALLERY_SUCCESS",
  UPDATE_GALLERY_FAILURE: "UPDATE_GALLERY_FAILURE",
  GET_IMP_LINKS_REQUEST: "GET_IMP_LINKS_REQUEST",
  GET_IMP_LINKS_SUCCESS: "GET_IMP_LINKS_SUCCESS",
  GET_IMP_LINKS_FAILURE: "GET_IMP_LINKS_FAILURE",
  CREATE_IMP_LINKS_REQUEST: "CREATE_IMP_LINKS_REQUEST",
  CREATE_IMP_LINKS_SUCCESS: "CREATE_IMP_LINKS_SUCCESS",
  CREATE_IMP_LINKS_FAILURE: "CREATE_IMP_LINKS_FAILURE",
  CREATE_IMP_URL_REQUEST: "CREATE_IMP_URL_REQUEST",
  CREATE_IMP_URL_SUCCESS: "CREATE_IMP_URL_SUCCESS",
  CREATE_IMP_URL_FAILURE: "CREATE_IMP_URL_FAILURE",
  UPDATE_IMP_URL_REQUEST: "UPDATE_IMP_URL_REQUEST",
  UPDATE_IMP_URL_SUCCESS: "UPDATE_IMP_URL_SUCCESS",
  UPDATE_IMP_URL_FAILURE: "UPDATE_IMP_URL_FAILURE",
  HIDE_IMP_URL_REQUEST: "HIDE_IMP_URL_REQUEST",
  HIDE_IMP_URL_SUCCESS: "HIDE_IMP_URL_SUCCESS",
  HIDE_IMP_URL_FAILURE: "HIDE_IMP_URL_FAILURE",
  GET_NEWS_REQUEST: "GET_NEWS_REQUEST",
  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAILURE: "GET_NEWS_FAILURE",
  CREATE_NEWS_REQUEST: "CREATE_NEWS_REQUEST",
  CREATE_NEWS_SUCCESS: "CREATE_NEWS_SUCCESS",
  CREATE_NEWS_FAILURE: "CREATE_NEWS_FAILURE",
  UPDATE_NEWS_REQUEST: "UPDATE_NEWS_REQUEST",
  UPDATE_NEWS_SUCCESS: "UPDATE_NEWS_SUCCESS",
  UPDATE_NEWS_FAILURE: "UPDATE_NEWS_FAILURE",
  DELETE_NEWS_REQUEST: "DELETE_NEWS_REQUEST",
  DELETE_NEWS_SUCCESS: "DELETE_NEWS_SUCCESS",
  DELETE_NEWS_FAILURE: "DELETE_NEWS_FAILURE",
  GET_RESULT_REQUEST: "GET_RESULT_REQUEST",
  GET_RESULT_SUCCESS: "GET_RESULT_SUCCESS",
  GET_RESULT_FAILURE: "GET_RESULT_FAILURE",
  CREATE_RESULT_REQUEST: "CREATE_RESULT_REQUEST",
  CREATE_RESULT_SUCCESS: "CREATE_RESULT_SUCCESS",
  CREATE_RESULT_FAILURE: "CREATE_RESULT_FAILURE",
  UPDATE_RESULT_REQUEST: "UPDATE_RESULT_REQUEST",
  UPDATE_RESULT_SUCCESS: "UPDATE_RESULT_SUCCESS",
  UPDATE_RESULT_FAILURE: "UPDATE_RESULT_FAILURE",
  CREATE_RESULT_COURSES_REQUEST: "CREATE_RESULT_COURSES_REQUEST",
  CREATE_RESULT_COURSES_SUCCESS: "CREATE_RESULT_COURSES_SUCCESS",
  CREATE_RESULT_COURSES_FAILURE: "CREATE_RESULT_COURSES_FAILURE",
  UPDATE_RESULT_COURSES_REQUEST: "UPDATE_RESULT_COURSES_REQUEST",
  UPDATE_RESULT_COURSES_SUCCESS: "UPDATE_RESULT_COURSES_SUCCESS",
  UPDATE_RESULT_COURSES_FAILURE: "UPDATE_RESULT_COURSES_FAILURE",
  GET_NEP_SYLLABUS_REQUEST: "GET_NEP_SYLLABUS_REQUEST",
  GET_NEP_SYLLABUS_SUCCESS: "GET_NEP_SYLLABUS_SUCCESS",
  GET_NEP_SYLLABUS_FAILURE: "GET_NEP_SYLLABUS_FAILURE",
  CREATE_NEP_SYLLABUS_REQUEST: "CREATE_NEP_SYLLABUS_REQUEST",
  CREATE_NEP_SYLLABUS_SUCCESS: "CREATE_NEP_SYLLABUS_SUCCESS",
  CREATE_NEP_SYLLABUS_FAILURE: "CREATE_NEP_SYLLABUS_FAILURE",
  UPDATE_NEP_SYLLABUS_REQUEST: "UPDATE_NEP_SYLLABUS_REQUEST",
  UPDATE_NEP_SYLLABUS_SUCCESS: "UPDATE_NEP_SYLLABUS_SUCCESS",
  UPDATE_NEP_SYLLABUS_FAILURE: "UPDATE_NEP_SYLLABUS_FAILURE",
  GET_ENTRANCE_SYLLABUS_REQUEST: "GET_ENTRANCE_SYLLABUS_REQUEST",
  GET_ENTRANCE_SYLLABUS_SUCCESS: "GET_ENTRANCE_SYLLABUS_SUCCESS",
  GET_ENTRANCE_SYLLABUS_FAILURE: "GET_ENTRANCE_SYLLABUS_FAILURE",
  CREATE_ENTRANCE_SYLLABUS_REQUEST: "CREATE_ENTRANCE_SYLLABUS_REQUEST",
  CREATE_ENTRANCE_SYLLABUS_SUCCESS: "CREATE_ENTRANCE_SYLLABUS_SUCCESS",
  CREATE_ENTRANCE_SYLLABUS_FAILURE: "CREATE_ENTRANCE_SYLLABUS_FAILURE",
  UPDATE_ENTRANCE_SYLLABUS_REQUEST: "UPDATE_ENTRANCE_SYLLABUS_REQUEST",
  UPDATE_ENTRANCE_SYLLABUS_SUCCESS: "UPDATE_ENTRANCE_SYLLABUS_SUCCESS",
  UPDATE_ENTRANCE_SYLLABUS_FAILURE: "UPDATE_ENTRANCE_SYLLABUS_FAILURE",
  GET_RECRUITMENT_REQUEST: "GET_RECRUITMENT_REQUEST",
  GET_RECRUITMENT_SUCCESS: "GET_RECRUITMENT_SUCCESS",
  GET_RECRUITMENT_FAILURE: "GET_RECRUITMENT_FAILURE",
  CREATE_RECRUITMENT_REQUEST: "CREATE_RECRUITMENT_REQUEST",
  CREATE_RECRUITMENT_SUCCESS: "CREATE_RECRUITMENT_SUCCESS",
  CREATE_RECRUITMENT_FAILURE: "CREATE_RECRUITMENT_FAILURE",
  UPDATE_RECRUITMENT_REQUEST: "UPDATE_RECRUITMENT_REQUEST",
  UPDATE_RECRUITMENT_SUCCESS: "UPDATE_RECRUITMENT_SUCCESS",
  UPDATE_RECRUITMENT_FAILURE: "UPDATE_RECRUITMENT_FAILURE",
  DELETE_RECRUITMENT_REQUEST: "DELETE_RECRUITMENT_REQUEST",
  DELETE_RECRUITMENT_SUCCESS: "DELETE_RECRUITMENT_SUCCESS",
  DELETE_RECRUITMENT_FAILURE: "DELETE_RECRUITMENT_FAILURE",
  GET_SPORTS_REQUEST: "GET_SPORTS_REQUEST",
  GET_SPORTS_SUCCESS: "GET_SPORTS_SUCCESS",
  GET_SPORTS_FAILURE: "GET_SPORTS_FAILURE",
  CREATE_SPORTS_REQUEST: "CREATE_SPORTS_REQUEST",
  CREATE_SPORTS_SUCCESS: "CREATE_SPORTS_SUCCESS",
  CREATE_SPORTS_FAILURE: "CREATE_SPORTS_FAILURE",
  UPDATE_SPORTS_REQUEST: "UPDATE_SPORTS_REQUEST",
  UPDATE_SPORTS_SUCCESS: "UPDATE_SPORTS_SUCCESS",
  UPDATE_SPORTS_FAILURE: "UPDATE_SPORTS_FAILURE",
  DELETE_SPORTS_REQUEST: "DELETE_SPORTS_REQUEST",
  DELETE_SPORTS_SUCCESS: "DELETE_SPORTS_SUCCESS",
  DELETE_SPORTS_FAILURE: "DELETE_SPORTS_FAILURE",
  GET_ENTRANCE_EXAM_REQUEST: "GET_ENTRANCE_EXAM_REQUEST",
  GET_ENTRANCE_EXAM_SUCCESS: "GET_ENTRANCE_EXAM_SUCCESS",
  GET_ENTRANCE_EXAM_FAILURE: "GET_ENTRANCE_EXAM_FAILURE",  
  CREATE_ENTRANCE_EXAM_REQUEST: "CREATE_ENTRANCE_EXAM_REQUEST",
  CREATE_ENTRANCE_EXAM_SUCCESS: "CREATE_ENTRANCE_EXAM_SUCCESS",
  CREATE_ENTRANCE_EXAM_FAILURE: "CREATE_ENTRANCE_EXAM_FAILURE",
  UPDATE_ENTRANCE_EXAM_REQUEST: "UPDATE_ENTRANCE_EXAM_REQUEST",
  UPDATE_ENTRANCE_EXAM_SUCCESS: "UPDATE_ENTRANCE_EXAM_SUCCESS",
  UPDATE_ENTRANCE_EXAM_FAILURE: "UPDATE_ENTRANCE_EXAM_FAILURE",
  ADD_DOC_TO_ENTRANCE_EXAM_REQUEST: "ADD_DOC_TO_ENTRANCE_EXAM_REQUEST",
  ADD_DOC_TO_ENTRANCE_EXAM_SUCCESS: "ADD_DOC_TO_ENTRANCE_EXAM_SUCCESS",
  ADD_DOC_TO_ENTRANCE_EXAM_FAILURE: "ADD_DOC_TO_ENTRANCE_EXAM_FAILURE",
  GET_EXAM_FORM_REQUEST: "GET_EXAM_FORM_REQUEST",
  GET_EXAM_FORM_SUCCESS: "GET_EXAM_FORM_SUCCESS",
  GET_EXAM_FORM_FAILURE: "GET_EXAM_FORM_FAILURE",
  CREATE_EXAM_FORM_REQUEST: "CREATE_EXAM_FORM_REQUEST",
  CREATE_EXAM_FORM_SUCCESS: "CREATE_EXAM_FORM_SUCCESS",
  CREATE_EXAM_FORM_FAILURE: "CREATE_EXAM_FORM_FAILURE",
  UPDATE_EXAM_FORM_REQUEST: "UPDATE_EXAM_FORM_REQUEST",
  UPDATE_EXAM_FORM_SUCCESS: "UPDATE_EXAM_FORM_SUCCESS",
  UPDATE_EXAM_FORM_FAILURE: "UPDATE_EXAM_FORM_FAILURE",  
  UPDATE_EXAM_FORM_DETAIL_REQUEST: "UPDATE_EXAM_FORM_DETAIL_REQUEST",
  UPDATE_EXAM_FORM_DETAIL_SUCCESS: "UPDATE_EXAM_FORM_DETAIL_SUCCESS",
  UPDATE_EXAM_FORM_DETAIL_FAILURE: "UPDATE_EXAM_FORM_DETAIL_FAILURE",
  CREATE_MEDIA_REQUEST: "CREATE_MEDIA_REQUEST",
  CREATE_MEDIA_SUCCESS: "CREATE_MEDIA_SUCCESS",
  CREATE_MEDIA_FAILURE: "CREATE_MEDIA_FAILURE",
  GET_CAMPUS_REQUEST: "GET_CAMPUS_REQUEST",
  GET_CAMPUS_SUCCESS: "GET_CAMPUS_SUCCESS",
  GET_CAMPUS_FAILURE: "GET_CAMPUS_FAILURE",
  CREATE_CAMPUS_REQUEST: "CREATE_CAMPUS_REQUEST",
  CREATE_CAMPUS_SUCCESS: "CREATE_CAMPUS_SUCCESS",
  CREATE_CAMPUS_FAILURE: "CREATE_CAMPUS_FAILURE",
  CREATE_CAMPUS_COLLEGE_REQUEST: "CREATE_CAMPUS_COLLEGE_REQUEST",
  CREATE_CAMPUS_COLLEGE_SUCCESS: "CREATE_CAMPUS_COLLEGE_SUCCESS",
  CREATE_CAMPUS_COLLEGE_FAILURE: "CREATE_CAMPUS_COLLEGE_FAILURE",  
  UPDATE_CAMPUS_COLLEGE_REQUEST: "UPDATE_CAMPUS_COLLEGE_REQUEST",
  UPDATE_CAMPUS_COLLEGE_SUCCESS: "UPDATE_CAMPUS_COLLEGE_SUCCESS",
  UPDATE_CAMPUS_COLLEGE_FAILURE: "UPDATE_CAMPUS_COLLEGE_FAILURE",
  GET_CBD_USERS_REQUEST: "GET_CBD_USERS_REQUEST",
  GET_CBD_USERS_SUCCESS: "GET_CBD_USERS_SUCCESS",
  GET_CBD_USERS_FAILURE: "GET_CBD_USERS_FAILURE",
  CREATE_CBD_USER_REQUEST: "CREATE_CBD_USER_REQUEST",
  CREATE_CBD_USER_SUCCESS: "CREATE_CBD_USER_SUCCESS",
  CREATE_CBD_USER_FAILURE: "CREATE_CBD_USER_FAILURE",
  UPDATE_CBD_USER_REQUEST: "UPDATE_CBD_USER_REQUEST",
  UPDATE_CBD_USER_SUCCESS: "UPDATE_CBD_USER_SUCCESS",
  UPDATE_CBD_USER_FAILURE: "UPDATE_CBD_USER_FAILURE",
  GET_SGR_USERS_REQUEST: "GET_SGR_USERS_REQUEST",
  GET_SGR_USERS_SUCCESS: "GET_SGR_USERS_SUCCESS",
  GET_SGR_USERS_FAILURE: "GET_SGR_USERS_FAILURE",
  CREATE_SGR_USER_REQUEST: "CREATE_SGR_USER_REQUEST",
  CREATE_SGR_USER_SUCCESS: "CREATE_SGR_USER_SUCCESS",
  CREATE_SGR_USER_FAILURE: "CREATE_SGR_USER_FAILURE",
  UPDATE_SGR_USER_REQUEST: "UPDATE_SGR_USER_REQUEST",
  UPDATE_SGR_USER_SUCCESS: "UPDATE_SGR_USER_SUCCESS",
  UPDATE_SGR_USER_FAILURE: "UPDATE_SGR_USER_FAILURE",
  RESET_APP: "RESET_APP",
  RESET: "RESET",
}

export const userConstants = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",  
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",  
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",  
  RESET_USER: "RESET_USER",
  RESET: "RESET"
}