import { SGRUsersPage } from "templates";

export const SGRUsers = () => {

  document.title = "SGR Users | ADMIN SSJU";

  return (
    <>
      <SGRUsersPage/>
    </>
  );
};

export default SGRUsers;
