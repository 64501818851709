import { useState } from "react";
import { FieldArray, Formik, Form } from "formik";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Flex, Input, InputSelect, Loader } from "atoms";
import { Fold } from "molecules";
import { createEvent } from "redux/actions";
import { isoDateToHtmlInputDate, upload } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),      
  description: Yup.string()
    .required("Description Required"),
  eventDate: Yup.string()
    .required("Event Date Required"), 
  eventSrc: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Source Title Required"),
      type: Yup.string().required("Source Type Required"),
      href: Yup.string().when("type", {
        is: "link",
        then: Yup.string().required("Source URL Required"),
      }),
      file: Yup.mixed().when("type", {
        is: "file",
        then: Yup.mixed().required("Source File Required"),
      }),
    })
  ).required("Source Required"),
});

export const EventForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  

  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      const form = {
        title: values.title,
        desc: values.description,
        eventDate: values.eventDate,
      }
      setLocalLoading(true);
      let src = [];
      for(let i = 0; i < values.eventSrc?.length; i++){
        if(values.eventSrc[i].type === "file"){
          let url = await upload(values.eventSrc[i].file);
          src.push({
            title: values.eventSrc[i].title,
            href: url,
          });
        }else{
          src.push({
            title: values.eventSrc[i].title,
            href: values.eventSrc[i].href,
          });
        }
      }
      form.eventSrc = src;
      setLocalLoading(false);
      dispatch(createEvent(form));
    }    
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <> 
      {localLoading && <Loader/>}     
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >
        <Fold
          title={type === "create" ? "Create Event" : "Update Event"}
          fold={fold}
          setFold={setFold}
        />        
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            description: data?.description || "",
            eventDate: data?.eventDate ? isoDateToHtmlInputDate(data?.eventDate) : "",
            eventSrc: data?.eventSrc || [],
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>  
                <Box mb="1rem">                   
                  <Input   
                    name="title"
                    type="text"
                    id="title"
                    touched={touched.title}
                    formik={true}
                    errors={errors.title}      
                    label="Title"
                    value={values.title}
                  />
                </Box>
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems="start"
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                >                                                       
                  <Input   
                    name="description"
                    type="text"
                    id="description"
                    touched={touched.description}
                    formik={true}
                    errors={errors.description}      
                    label="Description"
                    value={values.description}
                    as="textarea"                      
                    rows={5}     
                  />                 
                  <Flex 
                    flex="1"
                    flexDirection="column"
                    style={{gap: "1rem"}}
                  >                    
                    <Input
                      name="eventDate"
                      type="date"
                      id="eventDate"
                      touched={touched.eventDate}
                      formik={true}
                      errors={errors.eventDate}      
                      label="Event Date"
                      value={values.eventDate}
                    />
                    <Button
                      type="button"
                      variant="warning"
                      p="1rem 2rem"
                      borderRadius="0.5rem"
                      onClick={() => setFieldValue(
                        "eventSrc",
                        [...values.eventSrc, { title: "", href: "", type: "", file: null }]
                      )}
                    >
                      Add Source
                    </Button>
                  </Flex>                  
                </Flex>
                <FieldArray
                  name="eventSrc"
                  render={(arrayHelpers) => (
                    <Flex 
                      mt="1.5rem"
                      flexDirection="column"
                      style={{gap: "1rem"}}
                    >
                      {values.eventSrc?.map((item, index) => (
                        <Flex 
                          key={index}
                          flexDirection={{xs: "column", md: "row"}}
                          alignItems="start"
                          style={{gap: "1rem"}}
                        >                              
                          <Input
                            name={`eventSrc.${index}.title`}
                            type="text"
                            id={`eventSrc.${index}.title`}
                            touched={touched.eventSrc}
                            formik={true}
                            errors={errors.eventSrc}      
                            label={`Source Title ${index + 1}`}
                            value={values.eventSrc[index].title}
                          />                                                    
                          <InputSelect
                            value={values.eventSrc[index].type}
                            setValue={setFieldValue}
                            options={[
                              { value: "link", label: "Link" },
                              { value: "file", label: "File" },                            
                            ]}
                            label="Source Type"                                             
                            formik={true}
                            fieldName={`eventSrc.${index}.type`}
                            touched={touched.eventSrc}
                            errors={errors.eventSrc}  
                            name={`eventSrc.${index}.type`}
                            id={`eventSrc.${index}.type`}
                            placeholder={`Select Source Type ${index + 1}`}                                                       
                          />                          
                          {values.eventSrc[index].type === "link" && <Input
                            name={`eventSrc.${index}.href`}
                            type="text"
                            id={`eventSrc.${index}.href`}
                            touched={touched.eventSrc}
                            formik={true}
                            errors={errors.eventSrc}
                            label={`Source URL ${index + 1}`}
                            value={values.eventSrc[index].href}                            
                          />} 
                          {values.eventSrc[index].type === "file" && <Box flex="0.5">                          
                            <input
                              type="file"
                              name={`eventSrc.${index}.file`}
                              id={`eventSrc.${index}.file`}
                              onChange={(e) => {if(e.target.files[0]){
                                setFieldValue(`eventSrc.${index}.file`, e.target.files[0])
                              }}}                              
                            />
                          </Box>}                                                                          
                          <Button
                            type="button"
                            variant="danger"                            
                            width="fit-content"
                            px="2rem"
                            py="1rem"
                            alignItems="center"
                            justifyContent="center"                              
                            borderRadius="0.5rem"
                            mt="1rem"
                            onClick={() => arrayHelpers.remove(index)}                               
                          >                           
                            <MdDeleteOutline/>                        
                          </Button>                         
                        </Flex>
                      ))}
                    </Flex>
                  )}
                />
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}