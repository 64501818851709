import { useState } from "react";
import { Formik, Form } from "formik";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Box, Button, Flex, Image, Loader } from "atoms";
import { Fold } from "molecules";
import { createMediaCoverage } from "redux/actions";

export const MediaCoverageForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
  
  const [ images, setImages ] = useState([]);
  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){      
      if(!images || images.length === 0) return toast.error("Please select atleast one image");
      setLocalLoading(true);
      for(let i = 0; i < images.length; i++){
        let formData = new FormData();
        formData.append("image", images[i]);
        dispatch(createMediaCoverage(formData));
      }
      setLocalLoading(false);
    }    
    setImages(null);
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>   
      {localLoading && <Loader/>}   
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      >
        <Fold
          title={type === "create" ? "Create Media Coverage" : "Update Media Coverage"}
          fold={fold}
          setFold={setFold}
        />                                  
        {!fold && <Formik
          initialValues={{             
          }}          
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>                  
                <Button
                  as="label"
                  htmlFor="images"
                  variant="info"                  
                  type="button"
                  p="0.5rem 1rem"                      
                  width="fit-content"
                  borderRadius="0.5rem"                      
                >
                  Pick Images
                </Button>
                <input
                  type="file"
                  multiple
                  id="images"
                  name="images"
                  accept="image/*"                      
                  onChange={(e) => {
                    setImages([...e.target.files, ...images]);
                  }}
                  style={{
                    display: "none",
                  }}
                />                   
                <Flex
                  my="1.5rem"
                  flexWrap="wrap"
                  style={{gap: "1rem"}}                  
                >
                  {images?.map((image, index) => (
                    <Box>
                      <Image
                        src={URL.createObjectURL(image)}
                        width="10rem"
                        height="10rem"
                        objectFit="cover"
                        borderRadius="0.5rem"
                        alt={"image-" + index}
                      />
                      <Box
                        color="danger.100"
                        fontSize="1.8rem"
                        cursor="pointer"
                        my="1rem"
                        textAlign="center"
                      >
                        <MdDeleteOutline
                          onClick={() => {
                            const _images = [...images];
                            _images.splice(index, 1);
                            setImages(_images);
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Flex>        
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}
                    disabled={type === "update" ? true : false}
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}