import { MediaPage } from "templates";

export const MediaCoverage = () => {

  document.title = "MediaCoverage | ADMIN SSJU";

  return (
    <>
      <MediaPage/>
    </>
  );
};

export default MediaCoverage;
