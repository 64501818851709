import { ExamSchedulePage } from "templates";

export const ExamSchedule = () => {

  document.title = "Exam Schedule | ADMIN SSJU";

  return (
    <>
      <ExamSchedulePage/>
    </>
  );
};

export default ExamSchedule;
