import { useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Box, Button, Flex, Input, InputSelect, Loader } from "atoms";
import { Fold } from "molecules";
import { createExamSchedule } from "redux/actions";
import { upload } from "utils/utilities";

const validationSchema = Yup.object({
  title: Yup.string()    
    .required("Title Required"),
  type: Yup.string()
    .required("Type Required"),  
  file: Yup.mixed().when("type", {
    is: "file",
    then: Yup.mixed().required("File Required"),
    otherwise: Yup.mixed().notRequired(),
  }),
  url: Yup.string().when("type", {
    is: "link",
    then: Yup.string().required("URL Required"),
    otherwise: Yup.string().notRequired(),
  }),  
});

export const ExamScheduleForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  
    
  const [ localLoading, setLocalLoading ] = useState(false);
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    if(type === "create"){
      if(values.type === "file" && !values.file) return toast.error("File Required");
      if(values.type === "link" && !values.url) return toast.error("URL Required");
      let url;
      setLocalLoading(true);
      if(values.type === "file"){        
        url = await upload(values.file)
      }
      else url = values.url;
      const form = {
        title: values.title,
        href: url,
      }      
      setLocalLoading(false);      
      dispatch(createExamSchedule(form));      
    }else if(type === "update"){ 
      let url = data?.href;
      setLocalLoading(true);
      if(values.type === "file"){
        if(values.file) url = await upload(values.file);        
      }
      if(values.type === "link") url = values.url;
      const form = {
        id: data?.id,
        title: values.title,
        href: url,
      }
      setLocalLoading(false);
      dispatch(createExamSchedule(form));
    }        
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>    
      {localLoading && <Loader/>}
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      > 
        <Fold
          title={type === "create" ? "Create Exam Schedule" : "Update Exam Schedule"}
          fold={fold}
          setFold={setFold}
        />         
        {!fold && <Formik
          initialValues={{
            title: data?.title || "",
            type: type === "update" ? "link" : data?.type || "",
            url: data?.href || "",
            file: null,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>                
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}                  
                >                   
                  <Input
                    name="title"
                    type="text"
                    id="title"
                    touched={touched.title}
                    formik={true}
                    errors={errors.title}      
                    label="Title"
                    value={values.title}                                               
                  />                   
                  <InputSelect
                    value={values.type}
                    setValue={setFieldValue}
                    options={[
                      { value: "file", label: "File" },
                      { value: "link", label: "Link" },
                    ]}
                    label="Type*"                                             
                    formik={true}
                    fieldName="type"
                    touched={touched.type}
                    errors={errors.type}  
                    name="type"
                    id="type"
                    placeholder="Select Source Type"
                  />
                </Flex>                     
                {values.type === "file" && <Box mt="1.5em">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={(e) => setFieldValue("file", e.target.files[0])}                    
                  />
                </Box>}  
                {values.type === "link" && <Box mt="1.5em">
                  <Input
                    name="url"
                    type="text"
                    id="url"
                    touched={touched.url}
                    formik={true}
                    errors={errors.url}
                    label="URL"
                    value={values.url}                  
                  />
                </Box>}
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading || localLoading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}