import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FormControlLabel, Switch } from "@material-ui/core";

import { Box, Button, Flex, Input, InputSelect } from "atoms";
import { Fold } from "molecules";
import { createSGRUser, updateSGRUser } from "redux/actions";
import Axios from "utils/axios";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Required"),
  email: Yup.string()
    .required("Required")
    .email("Invalid email"),  
});

export const SGRUserForm = ({
  type,
  data,
  setType,
  setData,
  fold,
  setFold,
}) => {  

  const [ colleges, setColleges ] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if(colleges?.length === 0){
        let { data } = await Axios.get("/cbd/campus");
        if(data?.data?.length > 0){
          let temp = data?.data?.map(campus => campus.colleges.map(college => ({ label: college.name + " - " +  campus?.name, value: college.id }))).flat()
          setColleges(temp);
        }
      }
    }
    fetchData();
  }, [colleges]);

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);    

  const onSubmit = async (values, { resetForm, setSubmitting }) => { 
    const form = {
      name: values.name,
      email: values.email,      
      active: values.active,
    }
    if(values.phone) form.phone = values.phone;
    if(values?.collegeId) form.collegeId = values.collegeId;          
    if(type === "create"){      
      dispatch(createSGRUser(form));
    }else if(type === "update"){
      form.id = data?.id;
      dispatch(updateSGRUser(form));
    }
    setType("create");
    setData(null);
    resetForm();    
    setSubmitting(false)          
  }

  return (
    <>      
      <Box         
        mx="auto"        
        p="2rem 4rem"   
        bg="white"
        borderRadius="0.5rem"     
      > 
        <Fold
          title={type === "create" ? "Create User" : "Update User"}
          fold={fold}
          setFold={setFold}
        />         
        {!fold && <Formik
          initialValues={{
            name: data?.name || "",
            email: data?.email || "",
            phone: data?.phone || "",
            collegeId: data?.collegeId || "",
            active: data?.id ? data?.active : true,          
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ touched, errors, values, setFieldValue, resetForm }) => {
            return (
              <Form>                 
                <Input   
                  name="name"
                  type="text"
                  id="name"
                  touched={touched.name}
                  formik={true}
                  errors={errors.name}      
                  label="Name"                      
                  value={values.name}
                  mb="1rem"                  
                />                 
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                  mb="2rem"
                > 
                  <Input   
                    name="email"
                    type="email"
                    id="email"
                    touched={touched.email}
                    formik={true}
                    errors={errors.email}
                    label="Email"
                    value={values.email}                    
                  />                  
                  <Input   
                    name="phone"
                    type="text"
                    id="phone"
                    touched={touched.phone}
                    formik={true}
                    errors={errors.phone}
                    label="Phone"
                    value={values.phone}                                        
                  />                                           
                </Flex>                  
                <Flex 
                  flexDirection={{xs: "column", md: "row"}}
                  alignItems={{md: "center"}}
                  justifyContent={{md: "space-between"}}
                  style={{gap: "1rem"}}
                >
                  <InputSelect
                    value={values.collegeId}
                    setValue={setFieldValue}
                    options={colleges}
                    label="College*"                                             
                    formik={true}
                    fieldName="collegeId"
                    touched={touched.collegeId}
                    errors={errors.collegeId}  
                    name="collegeId"
                    id="collegeId"
                    placeholder="Select College"                 
                  />                                                     
                  <FormControlLabel
                    control={
                      <Switch                          
                        checked={values.active}
                        onChange={(e) => {
                          setFieldValue("active", e.target.checked);
                        }}
                        name="active"
                        color="secondary"
                      />
                    }
                    label="Active?"
                  />                  
                </Flex>                  
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  style={{gap: "1.5rem"}}
                >                                                        
                  <Button
                    variant="primary"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="submit"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    loading={loading}                    
                  >                  
                    {type === "create" ? "Create" : "Update"}                 
                  </Button>
                  <Button
                    variant="danger"   
                    width="fit-content"               
                    px="2rem"
                    py="1rem"
                    type="button"                  
                    mt="2rem"
                    fontSize="1.6rem"
                    borderRadius="2rem"
                    onClick={() => {
                      setType("create");
                      setData(null);
                      resetForm();
                    }}
                  >                  
                    Reset
                  </Button>
                </Flex>
              </Form>
            )
          }}
        </Formik>}
      </Box>
    </>
  )
}