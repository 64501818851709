import { ToolsPage } from "templates";

const Tools = () => {

  document.title = "Tools | ADMIN SSJU";

  return (
    <>
      <ToolsPage/>
    </>
  )
}

export default Tools