import { CampusPage } from "templates";

export const Campus = () => {

  document.title = "Campus | ADMIN SSJU";

  return (
    <>
      <CampusPage/>
    </>
  );
};

export default Campus;
