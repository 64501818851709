import { appConstants } from "redux/constants";
import Axios from "utils/axios";

export const getEvents = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_EVENT_REQUEST })
      let URL = `/admin/events?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EVENT_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createEvent = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_EVENT_REQUEST })
      let URL = `/events`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_EVENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateEvent = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_EVENT_REQUEST })
      let URL = `/events`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_EVENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){                 
      dispatch({
        type: appConstants.UPDATE_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteEvent = (id) => {
  return async (dispatch) => {    
    try{      
      dispatch({ type: appConstants.DELETE_EVENT_REQUEST })
      let URL = `/events/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_EVENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_EVENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getExamSchedule = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_EXAM_SCHEDULE_REQUEST })
      let URL = `/admin/exam-schedules?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EXAM_SCHEDULE_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EXAM_SCHEDULE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createExamSchedule = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_EXAM_SCHEDULE_REQUEST })
      let URL = `/exams/exam-schedule`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_EXAM_SCHEDULE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message,
          type: form?.id ? "update" : "create"
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_EXAM_SCHEDULE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateExamSchedule = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_EXAM_SCHEDULE_REQUEST })
      let URL = `/exams/exam-schedule`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_EXAM_SCHEDULE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_EXAM_SCHEDULE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getGallery = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_GALLERY_REQUEST })
      let URL = `/admin/albums?page=${page}`; 
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_GALLERY_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_GALLERY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createGallery = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_GALLERY_REQUEST })
      let URL = `/gallery`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_GALLERY_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_GALLERY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateGallery = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_GALLERY_REQUEST })
      let URL = `/gallery`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_GALLERY_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_GALLERY_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getImpLinks = (query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_IMP_LINKS_REQUEST })
      let URL = `/admin/imp-links`; 
      if(query) URL += `?query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_IMP_LINKS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_IMP_LINKS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createImpLink = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_IMP_LINKS_REQUEST })
      let URL = `/imp-links`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_IMP_LINKS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_IMP_LINKS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createImpUrl = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_IMP_URL_REQUEST })
      let URL = `/imp-links/url`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_IMP_URL_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_IMP_URL_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateImpUrl = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_IMP_URL_REQUEST })
      let URL = `/imp-links/url`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_IMP_URL_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_IMP_URL_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const hideImpUrl = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.HIDE_IMP_URL_REQUEST })
      let URL = `/imp-links/url/hide`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.HIDE_IMP_URL_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.HIDE_IMP_URL_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getNews = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_NEWS_REQUEST })
      let URL = `/admin/news?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_NEWS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createNews = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_NEWS_REQUEST })
      let URL = `/news`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_NEWS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateNews = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_NEWS_REQUEST })
      let URL = `/news`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_NEWS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteNews = (id) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.DELETE_NEWS_REQUEST })
      let URL = `/news/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_NEWS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_NEWS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getResults = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_RESULT_REQUEST })
      let URL = `/admin/results?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_RESULT_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_RESULT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createResult = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_RESULT_REQUEST })
      let URL = `/exams/result`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_RESULT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_RESULT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateResult = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_RESULT_REQUEST })
      let URL = `/exams/result`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_RESULT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_RESULT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createResultCourse = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_RESULT_COURSES_REQUEST })
      let URL = `/exams/result/course`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_RESULT_COURSES_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_RESULT_COURSES_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateResultCourse = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_RESULT_COURSES_REQUEST })
      let URL = `/exams/result/course`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_RESULT_COURSES_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_RESULT_COURSES_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getNEPSyllabus = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_NEP_SYLLABUS_REQUEST })
      let URL = `/admin/nep-syllabus?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_NEP_SYLLABUS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_NEP_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createNEPSyllabus = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_NEP_SYLLABUS_REQUEST })
      let URL = `/syllabus/nep`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_NEP_SYLLABUS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_NEP_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateNEPSyllabus = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_NEP_SYLLABUS_REQUEST })
      let URL = `/syllabus/nep/${id}`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_NEP_SYLLABUS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_NEP_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getEntranceSyllabus = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_ENTRANCE_SYLLABUS_REQUEST })
      let URL = `/admin/entrance-syllabus?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ENTRANCE_SYLLABUS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ENTRANCE_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createEntranceSyllabus = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_ENTRANCE_SYLLABUS_REQUEST })
      let URL = `/syllabus/entrance`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_ENTRANCE_SYLLABUS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_ENTRANCE_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateEntranceSyllabus = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_ENTRANCE_SYLLABUS_REQUEST })
      let URL = `/syllabus/entrance/${id}`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_ENTRANCE_SYLLABUS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_ENTRANCE_SYLLABUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getRecruitment = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_RECRUITMENT_REQUEST })
      let URL = `/admin/recruitment?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_RECRUITMENT_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_RECRUITMENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createRecruitment = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_RECRUITMENT_REQUEST })
      let URL = `/recruitments`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_RECRUITMENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_RECRUITMENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateRecruitment = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_RECRUITMENT_REQUEST })
      let URL = `/recruitments`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_RECRUITMENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_RECRUITMENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteRecruitment = (id) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.DELETE_RECRUITMENT_REQUEST })
      let URL = `/recruitments/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_RECRUITMENT_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_RECRUITMENT_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getSports = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_SPORTS_REQUEST })
      let URL = `/admin/sports?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_SPORTS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_SPORTS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createSports = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_SPORTS_REQUEST })
      let URL = `/sports`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_SPORTS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_SPORTS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateSports = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_SPORTS_REQUEST })
      let URL = `/sports`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_SPORTS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_SPORTS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const deleteSports = (id) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.DELETE_SPORTS_REQUEST })
      let URL = `/sports/${id}`;         
      const { data } = await Axios.delete(URL);                  
      dispatch({ 
        type: appConstants.DELETE_SPORTS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.DELETE_SPORTS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getEntranceExam = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_ENTRANCE_EXAM_REQUEST })
      let URL = `/admin/entrance-exam?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_ENTRANCE_EXAM_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_ENTRANCE_EXAM_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createEntranceExam = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_ENTRANCE_EXAM_REQUEST })
      let URL = `/exams/entrance-exam`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_ENTRANCE_EXAM_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_ENTRANCE_EXAM_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateEntranceExam = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_ENTRANCE_EXAM_REQUEST })
      let URL = `/exams/entrance-exam/${id}`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_ENTRANCE_EXAM_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_ENTRANCE_EXAM_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const addDocToEntranceExam = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.ADD_DOC_TO_ENTRANCE_EXAM_REQUEST })
      let URL = `/exams/entrance-exam/${id}/doc`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.ADD_DOC_TO_ENTRANCE_EXAM_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.ADD_DOC_TO_ENTRANCE_EXAM_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getExamForm = (page, query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_EXAM_FORM_REQUEST })
      let URL = `/admin/exam-forms?page=${page}`;   
      if(query) URL += `&query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_EXAM_FORM_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_EXAM_FORM_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createExamForm = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_EXAM_FORM_REQUEST })
      let URL = `/exams/exam-form`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_EXAM_FORM_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_EXAM_FORM_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateExamForm = (id, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_EXAM_FORM_REQUEST })
      let URL = `/exams/exam-form/${id}`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_EXAM_FORM_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_EXAM_FORM_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateExamFormDetail = (id, type, form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_EXAM_FORM_DETAIL_REQUEST })
      let URL = `/exams/exam-form/${id}/${type}`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_EXAM_FORM_DETAIL_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_EXAM_FORM_DETAIL_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createMediaCoverage = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_MEDIA_REQUEST })
      let URL = `/media-coverages`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_MEDIA_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_MEDIA_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getCampuses = (query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_CAMPUS_REQUEST })
      let URL = `/admin/campus`; 
      if(query) URL += `?query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_CAMPUS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_CAMPUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createCampus = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_CAMPUS_REQUEST })
      let URL = `/cbd/campus`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_CAMPUS_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_CAMPUS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createCampusCollege = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_CAMPUS_COLLEGE_REQUEST })
      let URL = `/cbd/campus/college`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_CAMPUS_COLLEGE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_CAMPUS_COLLEGE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateCampusCollege = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_CAMPUS_COLLEGE_REQUEST })
      let URL = `/cbd/campus/college`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_CAMPUS_COLLEGE_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_CAMPUS_COLLEGE_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getCBDUsers = (query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_CBD_USERS_REQUEST })
      let URL = `/admin/cbd-users`; 
      if(query) URL += `?query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_CBD_USERS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_CBD_USERS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createCBDUser = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_CBD_USER_REQUEST })
      let URL = `/cbd/user`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_CBD_USER_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_CBD_USER_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateCBDUser = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_CBD_USER_REQUEST })
      let URL = `/cbd/user`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_CBD_USER_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_CBD_USER_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const getSGRUsers = (query) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.GET_SGR_USERS_REQUEST })
      let URL = `/admin/sgr-users`; 
      if(query) URL += `?query=${query}`;
      const { data } = await Axios.get(URL);                  
      dispatch({ 
        type: appConstants.GET_SGR_USERS_SUCCESS,
        payload: data.data
      })
    }catch(error){           
      dispatch({
        type: appConstants.GET_SGR_USERS_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const createSGRUser = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.CREATE_SGR_USER_REQUEST })
      let URL = `/sgr/user`;         
      const { data } = await Axios.post(URL, form);                  
      dispatch({ 
        type: appConstants.CREATE_SGR_USER_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.CREATE_SGR_USER_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}

export const updateSGRUser = (form) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: appConstants.UPDATE_SGR_USER_REQUEST })
      let URL = `/sgr/user`;         
      const { data } = await Axios.put(URL, form);                  
      dispatch({ 
        type: appConstants.UPDATE_SGR_USER_SUCCESS,
        payload: {
          data: data.data,
          message: data.message
        }
      })
    }catch(error){           
      dispatch({
        type: appConstants.UPDATE_SGR_USER_FAILURE,
        payload: error.response?.data?.error
      })
    }
  };
}