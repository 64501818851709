import { BiNews } from 'react-icons/bi';
import { BsFillCalendarDateFill } from 'react-icons/bs';
import { FaSchool, FaExternalLinkAlt } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { GiVideoConference, GiTeacher } from 'react-icons/gi';
import { HiDocumentReport } from 'react-icons/hi';
import { IoMdSchool } from 'react-icons/io';
import { MdPictureAsPdf, MdOutlineDashboard, MdOutlineReviews, MdSportsKabaddi } from "react-icons/md";
import { RiGalleryFill } from 'react-icons/ri';
import { SiCoursera } from "react-icons/si";

export const sideNav = [
  {
    Icon: <MdOutlineDashboard/>,
    title: "Dashboard",
    to: "/dashboard",
    description: "View Admin dashboard",
  },
  {
    Icon: <SiCoursera/>,
    title: "Entrance Exam",
    to: "/entrance-exam",
    description: "Manage Entrance Exam",
  },
  {
    Icon: <IoMdSchool/>,
    title: "Entrance Syllabus",
    to: "/entrance-syllabus",
    description: "Manage Entrance Syllabus",
  },  
  {
    Icon: <BsFillCalendarDateFill/>,
    title: "Events",
    to: "/events",
    description: "Manage Events",
  },
  {
    Icon: <GiTeacher/>,
    title: "Exam Form",
    to: "/exam-form",
    description: "Manage Exam Forms",
  },
  {
    Icon: <FaSchool/>,
    title: "Exam Schedule",
    to: "/exam-schedule",
    description: "Manage Exam Schedule",
  },  
  {
    Icon: <RiGalleryFill/>,
    title: "Gallery",
    to: "/gallery",
    description: "Manage Gallery",
  },
  {
    Icon: <FaExternalLinkAlt/>,
    title: "Important Links",
    to: "/important-links",
    description: "Manage Important Links",
  },
  {
    Icon: <MdOutlineReviews/>,
    title: "Media Coverage",
    to: "/media-coverage",
    description: "Manage Media Coverage",
  },
  {
    Icon: <MdPictureAsPdf/>,
    title: "NEP Syllabus",
    to: "/nep-syllabus",
    description: "Manage NEP Syllabus",
  },
  {
    Icon: <BiNews/>,
    title: "News",
    to: "/news",
    description: "Manage News",
  },
  {
    Icon: <GiVideoConference/>,
    title: "Recruitment",
    to: "/recruitment",
    description: "Manage Recuritment",
  },
  {
    Icon: <IoMdSchool/>,
    title: "Result",
    to: "/result",
    description: "Manage Result",
  },
  {
    Icon: <MdSportsKabaddi/>,
    title: "Sports",
    to: "/sports",
    description: "Manage Sports",
  },
  {
    Icon: <HiDocumentReport/>,
    title: "Campus",
    to: "/campus",
    description: "Manage Campus",
  },
  {
    Icon: <FiUsers/>,
    title: "CBD Users",
    to: "/cbd-users",
    description: "Manage CBD Users",
  },  
  {
    Icon: <FiUsers/>,
    title: "SGR Users",
    to: "/sgr-users",
    description: "Manage SGR Users",
  },   
]