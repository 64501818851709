import { SportsPage } from "templates";

export const Sports = () => {

  document.title = "Sports | ADMIN SSJU";

  return (
    <>
      <SportsPage/>
    </>
  );
};

export default Sports;
